/* CSS Document */
.detailPage{
	.topItemInfo{
		&.itemSection{
			border-bottom: none;
		}
		.reviewStarWrap{
			text-align: left;
			.reviewStar{
				margin-top: 0;
			}
		}
	}
	/* 商品名 */
	.itemName{
		text-align:left;
		margin-bottom: 10px; 
    	padding-bottom: 10px;
		line-height: 1.6;
		border-bottom: 1px solid #333;
		word-wrap: break-word; 
		font-weight: bold;
	    font-size: 18px;
	}
	.originalCode{
		text-align: left;
		font-size: 12px;
	}
	.itemSection{
		border-bottom:1px solid #ddd;
		padding: 10px 10px 10px;  
	}

	.itemImg{
		border:none;
	}

	/* 商品画像 */
	.imgWrap{
		height: 40vh;
		margin-bottom:10px; 
		a{
			display: block;
			position: relative;
			width: auto;
			height: 100%;
			img{
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				max-height: 100%;
				margin: auto;
			}
		}
	}

	#thumb_images{
		padding: 0;
		letter-spacing: -0.4em; 
		text-align:left;
	}

	#thumb_images li{
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		width:20%;
		height:50px;
		line-height:inherit;
		box-sizing:border-box;
		position:relative;
		&:nth-child(n+6){
			margin-top: 10px;
		}
	}

	#thumb_images li img{
		width:auto;
		max-height:50px;    
		vertical-align:middle;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.heading{
		text-align:left;
		margin-bottom:20px; 
	}


	/* 価格 */
	.itemInfo{
		text-align:left;
		.itemDetail{
			margin: 15px 0;
			font-size: 14px;

		}
		.text1{
			margin-top: 10px;
			text-align: left;
			font-size: 14px;
			font-weight: bold;
			color: #2E3739;
			img{
				width: 24px;
				margin-right: 8px;
			}
		}
		.free{
			margin-left: 10px;
			padding: 2px 6px;
			background: #E22828;
			font-size: 12px;
			color: #fff;
		}
	}

	.itemInfo .reviewCount{
		vertical-align:middle;  
	}

	.itemInfo .reviewCount a{
		text-decoration:underline;  
	}

	.itemInfo p{
		// text-align:right;
		vertical-align:bottom;  
	}

	.markedPrice{
		font-size:12px;
		color:#999; 
	}

	.salesPrice{
		// text-align:right;
	}

	.price{
		font-weight:bold;
		display:inline-block;
		color: #E22828;
	}

	#taxPrice{
		font-size: 30px; 
	}

	.shopPoint{
		color:#666; 
		font-size:13px;
	}

	/* オプション */
	.itemAmount{
		overflow:hidden;    
	}

	.itemOption{
		text-align:left;
	}

	select{
		margin:5px auto 8px;    
	}

	.itemOption select{
		margin-bottom:12px; 
	}


	/* 数量 */
	.amount{
		display:table;  
		float:left;
	}

	.amount dt,
	.amount dd{
		display:table-cell; 
		padding:0 5px;
	}

	input[name="amount"]{
		width:6em;  
	}

	.stock{
		float:right;    
	}

	.stockBtn{
		display:inline-block;
		width:100%;
		background:#999;
	}

	.soldout{
		color:#cc0000;
		font-size:18px; 
		padding:10px 0;
	}

	.stockBtn:link{
		color:#fff; 
	}

	/* 説明文 */
	.content{
		font-size:14px;
		text-align:left;    
	}

	.more-content{
		margin-top: 20px;   
	}

	/* 追加商品画像 */
	#add_img{
		margin-top:10px;    
	}

	#add_img li{
		margin-bottom:10px; 
	}

	#add_img img{
		padding:5px 0;  
	}

	#add_img p{
		text-align:left;
	}   

	/* カートに入れるボタン */
	.btnCart,.btnRestock{
		background: #E34545; 
		color:#fff;
		width:100%;
		font-size:17px;
		padding:15px 0;
		font-weight:bold;
	}

	.contactLink{
		text-align: center;   
		font-size:12px;
		margin-top:10px;
	}
	.contactLink img{
		width:20px;
		height:20px;    
	}
	.review{
		margin-top: 10px;
	}


	/* 商品グループ名を非表示 */
	#group h4{
		display:none;   
	}

	/* レビュー */
	.itemReview{
		text-align:left;
		padding-top:50px;   
	}

	.reviewWrap{
		padding:10px 10px 20px 10px;    
	}

	.reviewAverage{
		font-weight:bold;
		font-size:19px; 
		display:inline-block;
		vertical-align:middle;
		margin-left:4px;
	}

	.reviewCount{
		color:#999;
		display:inline-block;
		vertical-align:middle;
	}

	.list_review{
		margin:16px auto -1px;  
	}

	.btnReview{
		display:inline-block;
		width:100%;
		text-align:center;
	}

	.list_review{
		border:1px solid #ccc;
	}

	.list_review li{
		border-bottom:1px dotted #ccc;  
		padding:16px;
	}

	.list_review li:last-child{
		border-bottom:none; 
	}

	.reviewInfo{
		overflow:hidden;
		margin-bottom:4px;  
	}

	.reviewName {
		float:left;
	}

	.reviewDate {
		color:#999;
		float:right;
		font-size:13px;
	}

	.list_review .reviewRate{
		margin-top:0;   
	}

	.list_review .reviewRateStar{
		width:15px;
		height:15px;
		background-size:15px 15px;  
	}

	.reviewComment{
		font-size:13px; 
		color:#666; 
		background:#eee;
		padding:10px;
	}

	.reviewMore .btnMore{
		border:1px solid #ccc;
		border-top:none;
		text-align:center;
		border-radius:0;    
		width:100%;
		padding:14px 0;
		margin-left:0;
		color:#666;
		font-weight:bold;
	}

	.reviewBtn .btnReview{
		margin-top:16px;
		background:#999;
		color:#fff; 
		padding:12px 0;
		margin-left:0;
	}

	.reviewNullText{
		text-align:center;  
		padding:16px 0 24px;
	}

	/* SNS */
	.snsBtn li{
		display:inline-block;   
		padding:0 5px;
	}

	.snsBtn iframe{
		vertical-align: middle;
	}

	.snsBtn .snsFacebook iframe{
		width: 85px !important;
	}

	/********************************** 以下はオプション使用時のスタイルになります */

	/* 会員グループ別価格 */
	.memberPrice{
		color:#e73656;  
	}


	/* 予約販売 */
	.reserveInfo{
		margin:16px 0;
		font-size:13px;
		background:#f2f2f2;
		padding:12px;
	}

	.reserveTitle{
		font-size:15px;
		font-weight:bold;
		margin-bottom:4px;
		color:#e73656;  
	}

	.reserveInfo p{
		text-align:left;
	}

	.reserveSaleDate{
		margin-bottom:4px;
	}

	.reserveSaleText,.reserveText{
		font-size:12px;
		color:#666; 
	}

	/* 定期購入 */
	.repeatTitle{
		color:#10a764;
		text-align:left;
		font-size:15px;
		margin:24px 0 16px; 
	}

	.repeatPrice{
		font-weight: bold;  
	}

	#rs_taxPrice{
		font-size:19px; 
	}

	.repeatsaleWrap .shopp{
		margin:2px 0;   
		color:#666;
		font-size:13px;
	}

	.repeatPrice table{
		width:100%; 
		margin-bottom:8px;
	}

	.repeatPrice th,.repeatPrice td{
		padding:4px 8px;
		text-align:left;    
		border:1px solid #ccc;
	}

	.repeatPrice th{
		background:#f2f2f2; 
	}

	#repeatGift{
		margin-top:24px;    
	}

	#repeatGift .giftName{
		text-align:left;
		font-weight:bold;
		margin-bottom:8px;  
	}

	#repeatGift .giftImg{
		display:inline-block;
		vertical-align:top;
		width:30%;  
	}

	#repeatGift .giftImg img{
		max-width:100%; 
	}

	#repeatGift .giftDetail{
		display:inline-block;   
		text-align:left
	}

	.btnRepeat{
		background:#10a764; 
		color:#fff;
		width:100%;
		font-size:17px;
		padding:15px 0;
		font-weight:bold;
	}







	/* 定期購入 */
	.repeatTitle{
		color:#10a764;
		text-align:left;
		font-size:15px;
		margin:24px 0 16px; 
	}
	 
	.repeatPrice{
		font-weight: bold;  
	}
	 
	#rs_taxPrice{
		font-size:19px; 
	}
	 
	.repeatsaleWrap .shopp{
		margin:2px 0;   
		color:#666;
		font-size:13px;
	}
	 
	.repeatPrice table{
		width:100%; 
		margin-bottom:8px;
	}
	 
	.repeatPrice th,.repeatPrice td{
		padding:4px 8px;
		text-align:left;    
		border:1px solid #ccc;
	}
	 
	.repeatPrice th{
		background:#f2f2f2; 
	}
	 
	#repeatGift{
		margin-top:24px;    
	}
	 
	#repeatGift .giftName{
		text-align:left;
		font-weight:bold;
		margin-bottom:8px;  
	}
	 
	#repeatGift .giftImg{
		display:inline-block;
		vertical-align:top;
		width:30%;  
	}
	 
	#repeatGift .giftImg img{
		max-width:100%; 
	}
	 
	#repeatGift .giftDetail{
		display:inline-block;   
		text-align:left
	}
	 
	.btnRepeat{
		background:#10a764; 
		color:#fff;
		width:100%;
		font-size:17px;
		padding:15px 0;
		font-weight:bold;
	}

	.titleWrap{
	  text-align: center;
	}
	.textTitle{
		text-align: center;
		font-size: 34px;
		color: #2E3739;
	}
	.textSubTitle{
		display: inline-block;
		position: relative;
		height: 28px;
		text-align: center;
		line-height: 28px;
		padding: 0 25px;
		margin-top: 15px;
		background: #D8AF1C;
		font-size: 14px;
		color: #fff;
	}
	.textSubTitle::before{
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -14px;
		width: 0;
		height: 0;
		margin: auto;
		border-right: 14px solid #D8AF1C;
		border-top: 14px solid transparent;
		border-bottom: 14px solid transparent;
	}
	.textSubTitle::after{
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: -14px;
		width: 0;
		height: 0;
		margin: auto;
		border-left: 14px solid #D8AF1C;
		border-top: 14px solid transparent;
		border-bottom: 14px solid transparent;
	}

	.reviewStarWrap{
		text-align: center;
	}
	.reviewStar{
		display: inline-block;
		margin-top: 20px;
	}


	.reviewSection{
		margin: 10px;
		padding: 20px 20px;
		border: 2px solid #D8AF1C;
		line-height: 1.8;
		letter-spacing: 1.2;
		.reviewHeading{
			text-align: center;
			margin: 10px -5px 20px;
			font-size: 18px;
			font-weight: bold;
			color: #D8AF1C; 
			&:before{
				content: "";
				display: inline-block;
				width: 1px;
				height: 35px;
				vertical-align: middle;
				margin-right: 10px;
				background: #D8AF1C;
				transform: rotate(-16deg);
			}
			&:after{
				content: "";
				display: inline-block;
				width: 1px;
				height: 35px;
				vertical-align: middle;
				margin-left: 10px;
				background: #D8AF1C;
				transform: rotate(16deg);
			}
		}
		.balloon{
			position: relative;
			margin-top: 10px;
			padding: 10px 15px;
			text-align: left;
			border-radius: 10px;
			background: #F8F4E9;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: bold;
			color: #333;
		}
		.balloonLeft{
			&:before{
				content: "";
				display: inline-block;
				position: absolute;
				bottom: -6px;
				left: -15px;
				width: 0;
				height: 0;
				border-right: 25px solid #F8F4E9;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				transform: rotate(-28deg);
			}
		}
		.balloonRight{
			&:after{
				content: "";
				display: inline-block;
				position: absolute;
				bottom: -6px;
				right: -15px;
				width: 0;
				height: 0;
				border-left: 25px solid #F8F4E9;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				transform: rotate(28deg);
			}
		}
	}
	.storageSection{
		margin: 40px 10px 10px;
		padding: 20px 25px;
		text-align: left;
		border: 2px solid #E2E2E2;
		.heading{
			font-size: 25px;
			font-weight: bold;
			color: #CC0000;
		}
		.text1{
			margin: 10px 0 10px;
			font-size: 16px;
			font-weight: bold;
		}
		.text2{
			font-size: 14px;
		}
	}
	
}

