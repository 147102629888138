.content1{
	.breadcrumb{
		margin: 20px 10px;
		text-align: left;
		font-size: 13px;
		color: #333;
		a{
			text-decoration: none;
			font-size: 13px;
			color: #333;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.mainview{
		padding: 40px 10px;
		box-sizing: border-box;
		background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content1/mainv.png') no-repeat top center;
		background-size: cover;
		.text1,.text2,.text3,.text4{
			text-align: center;
			margin: auto;
		}
		.text1{
			font-size: 18px;
			font-weight: bold;
			color: #2E3739;
		}
		.text2{
			font-size: 35px;
			font-weight: bold;
			color: #2E3739;
		}
		.text3{
			font-size: 18px;
			color: #5B8D26;
		}
		.text4{
			margin-top: 30px;
			line-height: 1.8;
			font-size: 13px;
			color: #2E3739;
		}
		.text5{
			display: flex;
			justify-content: center;
			margin-top: 20px;
			font-size: 14px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				box-sizing: border-box;
				width: 75px;
				height: 75px;
				margin: 0 7px;
				padding-bottom: 5px;
				border-radius: 50px;
				text-decoration: none;
				text-align: center;
				background: #5B8D26;
				color: #FFFEEE;
				&:after{
					content: "";
					position: absolute;
					bottom: 10px;
					right: 0;
					left: 0;
					width: 8px;
					height: 8px;
					margin: auto;
					border-bottom: 1px solid #FFFEEE;
					border-right: 1px solid #FFFEEE;
					transform: rotate(45deg);
				}
				&:hover{
					opacity: 0.7;
				}
				span{
					line-height: 0.8;
					font-size: 12px;
				}
			}
		}
	}
	.titleWrap{
		.titleText{
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			box-sizing: border-box;
			width: 65px;
			height: 65px;
			margin: -32px auto 0;
			border-radius: 50px;
			text-decoration: none;
			text-align: center;
			background: #5B8D26;
			color: #FFFEEE;

			&:hover{
				opacity: 0.7;
			}
			span{
				line-height: 0.8;
				font-size: 14px;
			}
		}
	}
	.titleText2{
		margin-top: 15px;
		padding: 0 10px;
		text-align: center;
		font-size: 28px;
		font-weight: bold;
		color: #5B8D26;
	}
	.text{
		margin-top: 20px;
		padding: 0 10px;
		text-align: center;
		font-size: 12px;
		color: #333;
	}
	.fastSection{
		margin-top: 20px;
	}
	.inexpensiveSection{
		margin-top: 30px;
		.text{
			margin: 20px;
		}

	}
	.safeSection{
		margin-top: 30px;
		.text{
			margin: 20px;
		}
	}

	.imageList{
		margin: 40px auto;
		li{
			border: 2px solid #E2E2E2;
			margin: 10px;

			.textWrap{
				line-height: 1.8;
				padding: 15px 15px;
				text-align: left;
				p{
					margin-top: 10px;
					font-size: 12px;
				}
			}
			.imageListTitle{
				font-size: 18px;
				font-weight: bold;
				span{
					line-height: 1.2;
					vertical-align: middle;
				}
				.listTitle{
					display: block;
					margin-top: 10px;
				}
				.icon{
					display: inline-block;
					margin-right: 15px;
					padding: 5px 13px;
					border-radius: 5px;
					background: #B77C17;
					font-size: 13px;
					color: #fff; 
					span{
						position: relative;
						top: -1px;
						margin-left: 5px;
						font-size: 20px;
					}
				}	
			}
		}
	}
}
.content2{
.breadcrumb{
	margin: 20px 10px;
	text-align: left;
	font-size: 13px;
	color: #333;
	a{
		text-decoration: none;
		font-size: 13px;
		color: #333;
		&:hover{
			text-decoration: underline;
		}
	}
}	
.mainview{
		padding: 40px 10px 70px;
		box-sizing: border-box;
		background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content2/mainv.png') no-repeat top center;
		background-size: cover;
		.text1,.text2,.text3,.text4{
			text-align: center;
			margin: auto;
		}
		.text1{
			font-size: 35px;
			font-weight: bold;
			color: #fff;
		}
		.text2{
			margin-top: 5px;
			font-size: 16px;
			color: #5B8D26;
		}
		.text3{
			margin-top: 20px;
			line-height: 1.8;
			text-shadow: 0 0 2px #000;
			font-size: 14px;
			color: #fff;
		}
	}

	.greetingSection{
		display: flex;
		padding: 5px 10px 40px;
		font-size: 17px;
		color: #333;
		.picture img{
			width: 389px;
		}
		.greetingText{
			padding: 0 20px;
			.greetingHeading{
				margin-top: 0;
				font-family: "游明朝",serif;
				font-size: 22px;
				font-weight: bold;
			}
			p{
				margin-top: 15px;
				font-size: 14px;
			}
			.name{
				margin-top: 30px;
				span{
					margin-left: 50px;
				}
			}
			.thanks{
				img{
					width: 150px;
				}
			}
		}

	}


	.staffSection{
		padding: 40px 0 65px;
		background: #F8F4E9;
		.staffSectionTitle{
			font-size: 26px;
			text-align: center;
			color: #584206;
			&:before{
				content: "";
				display: inline-block;
				position: relative;
				top: 5px;
				width: 0;
				height: 34px;
				margin-right: 20px;
				border-right: 1px solid #584206;
				transform: rotate(-15deg);
			}
			&:after{
				content: "";
				display: inline-block;
				position: relative;
				top: 5px;
				width: 0;
				height: 34px;
				margin-left: 20px;
				border-right: 1px solid #584206;
				transform: rotate(15deg);
			}
		}
		.staffList{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			color: #584206;
			margin: 0 -12px 0;
			li{
				width: 35%;
				margin: 34px 12px 0;
			}
			.picture img{
				width: 100%;
			}
			.department{
				margin-top: 15px;
				text-align: center;
				font-size: 10px;
			}
			.name{
				margin-top: 5px;
				text-align: center;
				font-size: 16px;
			}
		}
	}


	.bannerSection{
		padding: 40px 10px 40px;
		.bannerList{
			justify-content: space-between;
			li{
				&:nth-child(n+2){
					margin-top: 20px;
				}
				img{
					width: 100%;
				}
				.text{
					margin-top: 10px;
					font-size: 15px;
					color: #2E3739;
				}
			}
		}
	}


	.infoSection{
		padding: 0 0 40px;
		background: #F8F4E9;
	}
	.infoListWrap{
		padding: 10px;
	}
	.infoList{
		text-align: left;
		font-size: 15px;
		color: #2E3739;
		.heading{
			display: flex;
			align-items: center;
			margin: 60px auto 40px;
			padding: 0 25px;
			font-size: 28px;
			font-weight: bold;
		}
		.heading:before,
		.heading:after {
			content: "";
			flex-grow: 1;
			border-top: 1px solid;
		}
		.heading:before {
			margin-right: 1rem;
		}
		.heading:after {
			margin-left: 1rem;
		}
		dl{
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid #BEBEBE;
			dt,dd{
				box-sizing: border-box;
				width: 50%;
				padding: 20px;
				border-bottom: 1px solid #BEBEBE;
			}
			dt{
			}
			dd{
			}
		}
	}

	.contributionSection{
		padding: 40px 0 50px;

		.textTitle {
			text-align: center;
		 	font-size: 34px;
			color: #2E3739; }
		.textSubTitle {
			display: inline-block;
			position: relative;
			height: 28px;
			line-height: 28px;
			padding: 0 25px;
		 	margin-top: 15px;
		 	background: #D8AF1C;
		 	font-size: 14px;
		 	color: #fff; }

		.textSubTitle::before {
		  content: "";
		  display: inline-block;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  left: -14px;
		  width: 0;
		  height: 0;
		  margin: auto;
		  border-right: 14px solid #D8AF1C;
		  border-top: 14px solid transparent;
		  border-bottom: 14px solid transparent; }

		.textSubTitle::after {
		  content: "";
		  display: inline-block;
		  position: absolute;
		  top: 0;
		  bottom: 0;
		  right: -14px;
		  width: 0;
		  height: 0;
		  margin: auto;
		  border-left: 14px solid #D8AF1C;
		  border-top: 14px solid transparent;
		  border-bottom: 14px solid transparent; }

		.contributionList{
			margin-top: 40px;
			color: #333;
			li{
				margin: 20px 10px;
				padding: 30px 30px;
				border: 1px solid #E2E2E2;
			}
			.title{
				font-size: 18px;
				font-weight: bold;
			}
			.text{
				margin-top: 20px;
				line-height: 1.8;
				font-size: 15px;
			}
			.image{
				margin-top: 20px;
				margin-bottom: 30px;
			}
			.imageWrap{
				display: flex;
				justify-content: space-between;
				.image{
					width: 49%;
				}
			}
			img{
				width: 100%;
			}
		}
	}
}
.content3{
	.breadcrumb{
		margin: 20px 10px;
		text-align: left;
		font-size: 13px;
		color: #333;
		a{
			text-decoration: none;
			font-size: 13px;
			color: #333;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	.mainview{
		padding: 90px 0 30px;
		box-sizing: border-box;
		background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content3/mainv.png') no-repeat top center;
		background-size: cover;
		.text1,.text2,.text3,.text4{
			text-align: center;
			margin: auto;
		}
		.text1{
			text-shadow: 0 0 2px #000;
			font-size: 40px;
			font-weight: bold;
			color: #fff;
		}
		.text2{
			margin-top: 30px;
			line-height: 1.8;
			text-shadow: 0 0 2px #000;
			font-size: 14px;
			font-weight: bold;
			color: #fff;
		}
	}

	.reasonsSection{
		padding-bottom: 100px;
	}
	.reasonsList{
		margin-top: 25px;
		color: #333;
		li{
			&:nth-child(n+2){
				margin-top: 30px;
			}
			&:nth-child(odd){flex-direction: row;}
			&:nth-child(even){flex-direction: row-reverse;}
			.image{
				img{
					width: 100%;
				}
			}
			.textWrap{
				padding: 20px 10px;
			}
			.title{
				padding-bottom: 8px;
				line-height: 1.2;
				font-size: 28px;
				font-weight: bold;
			}
			.text{
				margin-top: 25px;
				line-height: 1.6;
				font-size: 14px;
			}
			.imageList{
				display: flex;
				justify-content: space-between;
				margin-top: 20px;
			}
		}
	}
}
.content4{
	.breadcrumb{
		margin: 20px 10px;
		text-align: left;
		font-size: 13px;
		color: #333;
		a{
			text-decoration: none;
			font-size: 13px;
			color: #333;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	.mainview{
		padding: 80px 10px 80px;
		box-sizing: border-box;
		background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content4/mainv.png') no-repeat top center;
		.text1,.text2,.text3,.text4{
			text-align: center;
			margin: auto;
		}
		.text1{
			text-shadow: 0 0 2px #000;
			font-size: 18px;
			font-weight: bold;
			color: #fff;
		}
		.text2{
			margin-top: 10px;
			text-shadow: 0 0 2px #000;
			font-size: 40px;
			font-weight: bold;
			color: #fff;
		}
		.text3{
			margin-top: 10px;
			line-height: 1.8;
			text-shadow: 0 0 2px #000;
			font-size: 12px;
			font-weight: bold;
			color: #fff;
		}
	}
	.placeSection{
		padding: 10px 0 40px;
		.title{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			color: #000;
		}
		.placeImage{
			margin-top: 35px;
			display: flex;
			justify-content: space-between;
		}
		.text{
			margin-top: 15px;
			padding: 10px;
			text-align: center;
			font-size: 15px;
			color: #333;
		}
	}
	.textTitle {
		text-align: center;
	 	font-size: 25px;
		color: #2E3739; }
	.textSubTitle {
		display: inline-block;
		position: relative;
		height: 28px;
		line-height: 28px;
		padding: 0 25px;
	 	margin-top: 15px;
	 	background: #D8AF1C;
	 	font-size: 14px;
	 	color: #fff; }

	.textSubTitle::before {
	  content: "";
	  display: inline-block;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: -14px;
	  width: 0;
	  height: 0;
	  margin: auto;
	  border-right: 14px solid #D8AF1C;
	  border-top: 14px solid transparent;
	  border-bottom: 14px solid transparent; }

	.textSubTitle::after {
	  content: "";
	  display: inline-block;
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  right: -14px;
	  width: 0;
	  height: 0;
	  margin: auto;
	  border-left: 14px solid #D8AF1C;
	  border-top: 14px solid transparent;
	  border-bottom: 14px solid transparent; }

	.productSection{
		padding: 45px 10px 60px;
		background: #F8F4E9;
		.title{
			text-align: center;
			font-size: 24px;
			color: #584206;
		}
		.typeList{
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			flex-wrap:wrap;
			li{
				width: 50%;
				box-sizing: border-box;
				img{
					width: 100%;
				}
			}
			a{
				display: inline-block;
				text-decoration: none;
				&:hover{
					opacity: 0.7;
				}
			}
			.image{}
			.text{
				height: 40px;
				line-height: 40px;
				text-align: center;
				background: #F17070;
				font-size: 16px;
				font-weight: bold;
				color: #fff;
				&.type2{background:#B77C17}
				&.type3{background:#BA4697}
				&.type4{background:#5246BA}
			}
		}
		.note{
			margin-top: 15px;
			padding: 0 10px;
			text-align: center;
			font-size: 15px;
			color: #333;
		}
		.polish{
			margin: 40px 0 0;
			padding: 28px 30px;
			background: #fff;
			.image{
				width: 100%;
				img{
					width: 100%;
				}
			}
			.text1{
				margin-top: 10px;
				font-size: 23px;
				font-weight: bold;
				color: #5B8D26;
			}
			.text2{
				margin-top: 10px;
				line-height: 1.8;
				font-size: 15px;
			}
		}
	}
	.reasonSection{
		padding: 60px 10px;
		.reasonsList{
			margin-top: 60px;
			color: #333;
			li{
				// display: flex;
				justify-content: space-between;
				&:nth-child(n+2){
					margin-top: 40px;
				}
				&:nth-child(odd){flex-direction: row;}
				&:nth-child(even){flex-direction: row-reverse;}
				.image{
					width: 100%;
					img{
						width: 100%;
					}
				}
				.textWrap{
					// width: 490px;
				}
				.title{
					margin-top: 10px;
					padding-bottom: 8px;
					line-height: 1.2;
					font-size: 28px;
					font-weight: bold;
				}
				.fz32{
					display: inline-block;
					margin-top: 5px;
					font-size: 26px;
				}
				.fz26{font-size: 22px;}
				.fz20{font-size: 16px;}
				.bold{
					margin-top: 25px;
					font-size: 14px;
					font-weight: bold;
				}
				.text{
					margin-top: 15px;
					line-height: 1.6;
					font-size: 14px;
				}
				.button{
					margin-top: 20px;
					text-align: center;
					a{
						display: inline-block;
						width: 100%;
						height: 50px;
						line-height: 50px;
						text-align: center;
						text-decoration: none;
						border-radius: 25px;
						background: #5B8D26;
						font-size: 15px;
						font-weight: bold;
						color: #fff;
						&:hover{
							opacity: 0.7;
						}
					}
				}
			}
		}
	}

	.compareSection{
		padding: 50px 10px 40px;
		background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content4/compare.png') no-repeat center;
		background-size: cover;
		.compareInner{
			// width: 800px;
			margin: auto;
			padding: 30px 15px 30px;
			box-sizing: border-box;
			background: rgba(255,255,255,0.7);
			.text1{
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #5B8D26;
			}
			.text2{
				margin-top: 10px;
				text-align: center;
				font-size: 25px;
				font-weight: bold;
				color: #333;			
			}
			.text3{
				margin-top: 30px;
				font-size: 12px;
			}
			.text4{
				margin-top: 30px;
				padding: 0 20px;
				font-size: 12px;
			}
			.text5{
				margin-top: 35px;
				padding: 0 20px;
				font-size: 12px;
			}
		}
	}

	.voiceSection{
		text-align: center;
		padding: 50px 10px 40px;
		.voiceList{
			li{
				margin-top: 45px;
			}
			.image{
				position: relative;
				height: 95vw;
				overflow: hidden;
				img{
					position: absolute;
					width: 100%;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					margin: auto;
				}
			}
			.textWrap{
				padding: 10px;
				text-align: left;
			}
			.name{
				font-size: 24px;
				font-weight: bold;
				color: #333;
				a{
					display: block;
					text-decoration: none;
					font-family: 'メイリオ','Mairyo',sans-serif;
					font-size: 18px;
					font-weight: normal;
					color: #707070;
					&:hover{
						text-decoration: underline;
					}
				}
			}
			.icon{
				display: inline-block;
				box-sizing: border-box;
				min-width: 97px;
				margin-top: 18px;
				padding: 5px;
				text-align: center;
				border-radius: 5px;
				font-size: 13px;
				font-weight: bold;
				background: #5B8D26;
				color: #fff;
			}
			.text{
				margin: 10px 0 15px;
				line-height: 1.6;
				font-size: 15px;
				color: #333;
			}
		}
	}

	.staffSection{
		padding: 40px 0 65px;
		background: #F8F4E9;
		.staffSectionTitle{
			font-size: 30px;
			text-align: center;
			color: #584206;
			&:before{
				content: "";
				display: inline-block;
				position: relative;
				top: 5px;
				width: 0;
				height: 34px;
				margin-right: 20px;
				border-right: 1px solid #584206;
				transform: rotate(-15deg);
			}
			&:after{
				content: "";
				display: inline-block;
				position: relative;
				top: 5px;
				width: 0;
				height: 34px;
				margin-left: 20px;
				border-right: 1px solid #584206;
				transform: rotate(15deg);
			}
		}
		.staffList{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			color: #584206;
			margin: 0 -12px 0;
			li{
				width: 170px;
				margin: 34px 12px 0;
			}
			.picture img{
				width: 100%;
			}
			.department{
				margin-top: 15px;
				text-align: center;
				font-size: 10px;
			}
			.name{
				margin-top: 5px;
				text-align: center;
				font-size: 16px;
			}
		}
	}

	.voiceSection{
		.heading{
			display: flex;
			align-items: center;
			// width: 600px;
			margin: 70px auto 50px;
			font-size: 18px;
			font-weight: bold;
		}
		.heading:before,
		.heading:after {
			content: "";
			flex-grow: 1;
			border-top: 1px solid;
		}
		.heading:before {
			margin-right: 1rem;
		}
		.heading:after {
			margin-left: 1rem;
		}
		.telBox{
			box-sizing: border-box;
			// width: 900px;
			padding: 15px 10px;
			margin: auto;
			border: 1px solid #E2E2E2;
			p{
				text-align: center;
			}
			.textTop{
				font-size: 16px;
				font-weight: bold;
				color: #333;
			}
			.tel{
				margin-top: 10px;
				font-size: 26px;
				font-family: 'Century Gothic';
				font-weight: bold;
				color: #333;
				.num{
					margin-left: 20px;
					font-size: 30px;
				}
			}
			.time{
				font-size: 17px;
				font-weight: bold;
				color: #333;
			}
		}
		.formButton{
			margin-top: 60px;
			text-align: center;
			a{
				display: inline-block;
				width: 360px;
				height: 50px;
				line-height: 50px;
				border-radius: 25px;
				text-align: center;
				text-decoration: none;
				background: #5B8D26;
				font-size: 15px;
				font-weight: bold;
				color: #fff;
				&:hover{
					opacity: 0.7;
				}
			}
		}
	}
}




/*****************************************************
*****************************************************
all
*****************************************************
*****************************************************/
.breadcrumb span {
	padding: 0 10px;
	color: #666;
	font-size: 11px;
	vertical-align: middle;
}
.inline-ul {
	display: flex;
	justify-content: center;
}

/* variable
----------------------------------------------- */
$color-main: #2E3739;
$color-sub1: #D8AF1C;
$color-sub2:#5B8D26;
$color-sub3:#1C74D8;
$color-sub4:#E34545;

$color-bg-gray1: #F7F7F7;
$color-bg-gray2: #E2E2E2;

/* hedden
----------------------------------------------- */
.sp-hidden {
    display: none !important;
}
// .pc-hidden {
//     display: none !important;
// }

/* column
----------------------------------------------- */
.flex-wrap {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
}
.flex-item {
	flex: 1 1 auto;
}

/*****************************************************
*****************************************************
ショッピングインフォメーション
*****************************************************
*****************************************************/


/* wp-style single-style
----------------------------------------------- */
.archive-title {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 1px solid $color-main;
  margin: 0;
  padding: 0 0 15px;
  line-height: 1.2;
}
.wp-style .entry-content {
	padding-top: 100px;
	margin-top: -100px;
  h2 {
      color: #333;
      background: #FFFEEE;
      border: 1px solid $color-sub1;
      padding: 15px 18px;
      margin: 60px 0 40px;
      border-radius: 3px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
  }
  h3 {
      border: none;
      border-bottom: 1px solid $color-sub1;
      padding: 0 5px 10px;
      margin: 50px 0 40px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
  }
  h4 {
      border: none;
      margin: 40px 0;
      padding: 5px 0;
      border-left: 3px solid $color-sub1;
      padding-left: 15px;
      font-size: 18px;
      line-height: 1.2;
  }
  h5,h6 {
    font-size: 17px;
  }
  p {
	    font-size: 16px;
	    line-height: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 1.2em 0;
  }
  li {
		list-style-type: disc;
  }
  ul,ol {
      padding-left: 1.6em;
      margin: 1em 0;
      line-height: 1.6;
      text-align: left;
      ul,ol {
          margin-top: .5em;
          margin-bottom: .5em;
      }
  }
  ul > li > ul {
      list-style-type: circle;
  }
  dl {
      padding-left: .5em;
      line-height: 1.6;
      text-align: left;
      dt {
          margin-bottom: .5em;
          font-weight: bold;
      }
      dd {
        margin-bottom: .25em;
      }
  }
  table {
      width: 100%;
      font-size: 95%;
      margin-bottom: 3em;
  }
  table caption {
      margin: 0 0 7px;
      font-size: 90%;
      opacity: 0.8;
      text-transform: uppercase;
      letter-spacing: 1px;
  }
  td,
  th {
      padding: 1.2em .8em;
      border: 1px solid #d8d8d8;
  }
  td input {
      margin:3px 0;
  }
  th {
      background: rgba(0, 0, 0, 0.05);
      text-align: left;
  }
  blockquote {
      padding: 3% 4%;
      margin: 2em 0;
      position:relative;
      border: none;
      border-radius: 5px;
      font-size: .85em;
      background: $color-bg-gray1;
      color: #666;

      &::before,
      &::after {
          color: #bbb;
          position: absolute;
          display: block;
          line-height: 1;
          font-size: 45px;
      }
      &::before {
          content: "“";
          left: 15px;
          top: 15px;
      }
      &::after {
          content: "”";
          right: 13px;
          bottom: -15px;
      }
      img {
          width: auto;
          max-width: 100%;
      }
  }
  strong {
      font-size: 110%;
      display: inline-block;
      padding: 0 0.3rem;
      margin: 0;
      position: relative;
      &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 2px;
          height: 3px;
          background: $color-sub1;
      }
  }
}

#page-shopping-guide {
	font-size: 16px;
	line-height: 2;
	.flex-wrap {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		padding: 15px;
		box-sizing: border-box;
	}
	.flex-item {
		flex: 1 1 100%;
	}
	.main-contents {
		order: 2;
	}
	.anchor-wrap {
		order: 1;
		margin-bottom: 40px;
	}
	.anchor-nav {
		position: sticky;
    top: 40px;
		dt {
		  background: $color-main;
		  color: #FFF;
		  padding: 6px 10px;
		  margin-bottom: 4px;
		  font-size: 16px;
		  font-weight: bold;
		  border-radius: 3px;
		}
		dd a {
	    display: block;
	    border-bottom: 1px solid #DDD;
	    color: #333;
	    text-decoration: none;
	    padding: 6px 0;
	    font-size: 14px;
			transition: all 0.3s ease;
			&:hover {
				background-color: #f5f8fa;
				transition: all 0.3s ease;
				color: #333;
			}
		}
	}
}



/*****************************************************
*****************************************************
安心安全の取り組み
*****************************************************
*****************************************************/
.wrapper {
	position: relative;
}
.mainview-wrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
	background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/bg-page-safety.jpg") #eee no-repeat 50%;
	background-size: cover;
	height: 70vh;
	display: flex;
	justify-content: center;
	align-items: center;
	h1 {
		text-align: center;
		font-size: 38px;
		font-weight: bold;
		letter-spacing: .1em;
		line-height: 1.5;
		color: #FFF;
		text-shadow:0px 0px 6px #000;
	}
	span {
		display: block;
	}
}


/* safety-contents
----------------------------------------------- */
#page-safety {
	padding-top: 72vh;
	.safety-contents {
		margin-top: 100px;
		padding: 0 15px;
		* {
			// text-align: center;
			box-sizing: border-box;
		}

	  h2 {
	      color: #333;
	      margin: 40px 0 30px;
	      font-size: 28px;
	      font-weight: bold;
	      line-height: 1.2;
	  }
	  p {
		    font-size: 16px;
		    line-height: 2;
	      overflow: hidden;
	      text-overflow: ellipsis;
	      margin: 1em 0;
	  }
	}
	.bd-sand-side {
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:before, &:after {
		  content: "";
		  flex: 0 0 60px;
		  height: 1px;
		  background: #333;
		  display: block;
		}
		&:before {
		  margin-right: 20px;
		}
		&:after {
		  margin-left: 20px;
		}
		font-size: 30px;
		margin: 0 auto 30px;
	}
	.bd-content {
		box-sizing: border-box;
		border: 2px solid #E2E2E2;
		padding: 20px 0 10px;
		margin: 40px auto;
	}
	.process-type {
		margin-bottom: 40px;
		flex-flow: row wrap;
		li {
			padding: 2px;
			font-weight: bold;
			flex: 0 0 100%;
		}
		.type01 {
			color: #BA4697;
		}
		.type02 {
			color: #F17070;
		}
		.type03 {
			color: #378DC7;
		}
	}
	.flex-wrap {
		justify-content: center;
	}
	.flex-item {
		flex: 0 0 calc(100%/2 - 7px);
		padding: 0 7px;
		margin-bottom: 30px;
		* {
			text-align: left;
		}
		.num {
			display: block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			color: #FFF;
			background: #666;
			text-align: center;
			font-family: 'arial' sans-serif;
			font-weight: bold;
			font-size: 11px;
			border-radius: 100px;
			margin-right: 8px;
			&.type01 {
				background: #BA4697;
			}
			&.type02 {
				background: #F17070;
			}
			&.type03 {
				background: #378DC7;
			}
		}
		.title-wrap {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			line-height: 1.2;
			font-size: 15px;
			font-weight: bold;
			margin: 15px 0 10px;
		}
		.txt-wrap {
			line-height: 1.6;
			font-size: 12px;
			margin: 0;
		}
	}

	#polished-rice {
		margin-top: 50px;
	}


}


/*****************************************************
*****************************************************
人気お米ランキング
*****************************************************
*****************************************************/
#page-ranking {
	.textTitle {
	    font-size: 34px;
	    color: #2E3739;
	}
	.textSubTitle {
    display: inline-block;
    position: relative;
    height: 28px;
    line-height: 28px;
    padding: 0 25px;
    margin-top: 15px;
    background: #D8AF1C;
    font-size: 14px;
    color: #fff;
    &::before,
		&::after {
	    content: "";
	    display: inline-block;
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 0;
	    height: 0;
	    margin: auto;
		}
    &::before {
	    left: -14px;
	    border-right: 14px solid #D8AF1C;
	    border-top: 14px solid transparent;
	    border-bottom: 14px solid transparent;
		}
		&::after {
	    right: -14px;
	    border-left: 14px solid #D8AF1C;
	    border-top: 14px solid transparent;
	    border-bottom: 14px solid transparent;
		}
	}

	.main-contents,
	.main-contents * {
		text-align: center;
	}
	.entry-content {
		padding-left: 5px;
		padding-right: 5px;
	  h2 {
			padding: 12px;
			font-size: 21px;
			font-weight: bold;
			margin-left: 10px;
			margin-right: 10px;
	  }
	}
	.flex-wrap.ranking {
		margin: 0;
		padding: 0 0 50px;
		justify-content: center;

		.flex-item {
			flex: 0 1 calc(100%/2 - 10px);
			padding: 5px;
			margin-bottom: 10px;
			list-style: none;
			&:first-child {
				flex: 0 1 100%;
			}
			em {
				display: block;
				text-indent: -9999em;
			}
			p {
				margin: 8px 0;
				font-weight: bold;
				line-height: 1.5;
			}
			a {
				text-decoration: none;
				color: #333;
				&:hover {
					text-decoration: underline;
				}
			}
			.img-wrap {
				margin-bottom: 15px;
				img {
					width: auto;
					max-width: 253px;
				}
			}
			.title-wrap {
				font-size: 15px;
			}
			.txt-wrap a {
				color: $color-sub4;
				font-size: 15px;
				.bx-span {
					padding: 5px;
					margin-right: 6px;
					display: inline-block;
					color: #FFF;
					background: $color-sub4;
					font-size: 10px;
					line-height: 1;
					vertical-align: middle;
				}
				.tax {
					font-size: 10px;
					line-height: 1;
				}
			}
		}
		.rank1 em {
		  background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon1.png) no-repeat center;
		  width: 40px;
		  height: 40px;
		  margin: auto;
		}

		.rank2 em {
		  background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon2.png) no-repeat center;
		  width: 40px;
		  height: 40px;
		  margin: auto;
		}

		.rank3 em {
		  background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon3.png) no-repeat center;
		  width: 40px;
		  height: 40px;
		  margin: auto;
		}

		.rank4 em {
		  background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon4.png) no-repeat center;
		  width: 40px;
		  height: 40px;
		  margin: auto;
		}

		.rank5 em {
		  background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon5.png) no-repeat center;
		  width: 40px;
		  height: 40px;
		  margin: auto;
		}
	}
}



/*****************************************************
*****************************************************
TOPの基礎知識（WP）
*****************************************************
*****************************************************/
.howto {
  text-align: center;
}
.inner-howto {
  margin-top: 40px;
}
#iframe-howto {
  height: 550px;
}
.detailButton {
  padding: 0 15px;
  text-align: center;
  a {
    display: block;
    height: 42px;
    margin-top: 20px;
    line-height: 42px;
    text-decoration: none;
    border-radius: 30px;
    background: #5B8D26;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
}