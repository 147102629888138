@charset "UTF-8";
/*************** reset */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

img {
  max-width: 100%; }

* {
  webkit-appearance: none;
  -webkit-appearance: none; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

div, p {
  word-break: break-all; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input, select, textarea {
  vertical-align: middle;
  border: none;
  font-size: inherit; }

input[type="text"],
input[type="number"],
input[type="search"],
select {
  padding: 10px;
  border: 1px solid #808080;
  box-sizing: border-box;
  width: 100%; }

select {
  background: url("/smartphone/images/template/5/icon-select.png") no-repeat 92% center;
  background-size: 20px 20px;
  padding-right: 36px; }

.clear:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

/***************************************

	  layout

***************************************/
body {
  font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Meiryo","メイリオ","ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  background: #ffffff;
  text-align: center;
  line-height: 1.5;
  color: #333;
  position: relative;
  overflow-x: hidden;
  right: 0;
  font-size: 14px; }

html body {
  margin-top: 88px; }

img {
  max-width: 100%;
  vertical-align: bottom; }

img[width="1"], img[width="0"] {
  display: none !important; }

/***************************************

	  head

***************************************/
.topText {
  padding: 2px;
  text-align: center;
  background: #5B8D26;
  font-size: 12px;
  font-weight: bold;
  color: #fff; }
  .topText span {
    font-size: 10px;
    font-weight: normal; }

.navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  background: #fff;
  border-bottom: 1px solid #E2E2E2; }
  .navBar ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    min-height: 64px; }
    .navBar ul li:first-child {
      flex-basis: 52%;
      flex-grow: 1;
      text-align: left; }
      .navBar ul li:first-child img {
        width: 198px; }
      .navBar ul li:first-child a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 4px 10px 0 10px; }
    .navBar ul li:nth-child(n+2) {
      background: #fff;
      box-sizing: border-box;
      font-size: 10px;
      flex-basis: 16%;
      padding: 0 0 6px; }
    .navBar ul li.navOder {
      display: flex;
      border-left: 1px solid #E2E2E2;
      color: #E34545; }
      .navBar ul li.navOder i {
        font-size: 24px;
        display: block;
        margin-bottom: 6px; }
      .navBar ul li.navOder a {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
        color: #E34545; }
    .navBar ul li.navCart {
      display: flex;
      border-right: 1px solid #E2E2E2;
      border-left: 1px solid #E2E2E2;
      color: #E34545; }
      .navBar ul li.navCart img {
        width: 30px;
        display: block; }
      .navBar ul li.navCart a {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        text-decoration: none;
        font-weight: bold;
        color: #E34545; }
        .navBar ul li.navCart a img {
          margin-bottom: 7px; }
    .navBar ul li.navMenu {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold; }
      .navBar ul li.navMenu div {
        display: block;
        position: relative;
        width: 100%;
        height: 18px;
        margin-bottom: 10px; }
        .navBar ul li.navMenu div span {
          position: absolute;
          display: block;
          width: 20px;
          height: 2px;
          right: 0;
          left: 0;
          margin: auto;
          background: #2E3739; }
        .navBar ul li.navMenu div span:nth-child(1) {
          top: 0; }
        .navBar ul li.navMenu div span:nth-child(2) {
          top: 0;
          bottom: 0; }
        .navBar ul li.navMenu div span:nth-child(3) {
          bottom: 0; }

.open .navBar ul li.navMenu div span {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  background: #2E3739; }

.open .navBar ul li.navMenu div span:nth-child(1) {
  top: 0;
  bottom: 0;
  transform: rotate(45deg); }

.open .navBar ul li.navMenu div span:nth-child(2) {
  transform: rotate(45deg); }

.open .navBar ul li.navMenu div span:nth-child(3) {
  top: 0;
  bottom: 0;
  transform: rotate(135deg); }

.navCartIcon {
  position: absolute;
  right: 12px;
  top: 8px;
  width: 17px;
  height: 17px;
  font-size: 11px;
  border-radius: 9px;
  background: #c7004c;
  box-shadow: 0px 1px 2px #ccc;
  line-height: 17px;
  color: #fff;
  font-family: Arial, Helvetica, "sans-serif"; }

#sideMenu {
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  right: -90%;
  padding: 121px 25px 25px;
  box-sizing: border-box;
  background: #fff;
  overflow: scroll;
  z-index: 100; }

.open .sideMenuBg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #333;
  opacity: 0.7;
  z-index: 90; }

.heading {
  display: flex;
  align-items: center;
  margin: 30px auto 20px;
  font-size: 16px;
  font-weight: bold; }

.heading:before,
.heading:after {
  content: "";
  flex-grow: 1;
  border-top: 1px solid; }

.heading:before {
  margin-right: 1rem; }

.heading:after {
  margin-left: 1rem; }

.searchArea {
  background: #333;
  padding: 15px 10px;
  text-align: left; }

input[name="brand_search_form"] {
  display: inline-block;
  width: 80%;
  padding: 10px;
  box-sizing: border-box;
  border: none; }

input[name="brand_search_button"] {
  border-radius: 6px;
  box-sizing: border-box;
  background: url("/smartphone/images/template/5/icon-search.png") no-repeat center center;
  background-size: 26px 26px;
  text-indent: -9999em;
  width: 40px;
  height: 30px; }

.sideNav {
  margin: 15px 0 100px;
  text-align: left;
  font-size: 15px; }

.sideNav, .sideNav a {
  color: #333; }

.sideNav li a {
  padding: 10px;
  display: block; }

.sideNav-login a {
  display: block;
  height: 44px;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #95989A;
  border-radius: 8px;
  background: #F7F7F7;
  font-size: 15px;
  font-weight: bold;
  color: #2E3739; }

.topButton {
  display: flex;
  margin: 30px -5px 30px; }
  .topButton p {
    width: 50%; }
  .topButton a {
    display: block;
    height: 44px;
    margin: 0 5px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #95989A;
    border-radius: 8px;
    background: #F7F7F7;
    font-size: 15px;
    font-weight: bold;
    color: #2E3739; }

.heading2 {
  margin: 20px 0;
  font-size: 15px;
  font-weight: bold;
  color: #2E3739; }

.heading3 {
  height: 30px;
  margin: 20px 0 0;
  line-height: 30px;
  text-align: center;
  background: #2E3739;
  font-size: 15px;
  font-weight: bold;
  color: #fff; }

.sideCategory2, .sideCategory3 {
  border-bottom: 1px solid #E2E2E2;
  padding-bottom: 10px; }
  .sideCategory2 > ul, .sideCategory3 > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .sideCategory2 > ul > li, .sideCategory3 > ul > li {
      display: inline-block;
      width: 50%;
      margin: 0 -8px; }
      .sideCategory2 > ul > li > a, .sideCategory3 > ul > li > a {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        text-decoration: none;
        border: 1px solid transparent;
        font-size: 15px;
        color: #333; }
        .sideCategory2 > ul > li > a img, .sideCategory3 > ul > li > a img {
          max-width: 60px;
          max-height: 68px;
          margin-right: 12px; }
        .sideCategory2 > ul > li > a.M_currentAnchor, .sideCategory3 > ul > li > a.M_currentAnchor {
          border: 1px solid #D8AF1C;
          background: #FFFEEE;
          font-weight: normal; }

.sideCategory3 > ul > li > a {
  padding: 5px 8px; }

#category_list2 {
  margin-top: 5px; }

#category_list2 li a {
  font-size: 13px; }

.bottomButton {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -5px 30px; }
  .bottomButton p {
    width: 50%; }
  .bottomButton a {
    display: block;
    height: 44px;
    margin: 5px 5px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    border: 1px solid #95989A;
    border-radius: 8px;
    background: #F7F7F7;
    font-size: 15px;
    font-weight: bold;
    color: #2E3739; }

.list li a {
  display: block;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  border-bottom: 1px solid #E2E2E2;
  font-size: 13px;
  font-weight: bold;
  color: #2E3739; }

.list li.child a {
  padding-left: 25px; }

/***************************************

	  common

***************************************/
/* スライダー下の余白 */
#mainContents .bx-wrapper {
  margin-bottom: 35px; }

#list_item,
.item,
.rankingList {
  text-align: left;
  margin: 20px auto;
  letter-spacing: -0.4em; }

#list_item li,
.item li,
.rankingList li {
  display: inline-block;
  vertical-align: top;
  width: 31%;
  margin-left: 2%;
  font-size: 12px;
  margin-bottom: 15px;
  text-align: left;
  letter-spacing: normal; }

#list_item li:nth-child(3n),
.item li:nth-child(3n),
.rankingList li:nth-child(3n) {
  margin-right: 1%; }

#list_item .itemImgWrap,
.item .itemImg,
.rankingList .itemImg {
  text-align: center;
  margin-bottom: 5px;
  position: relative; }

.rankingNum {
  background: #2490d0;
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  line-height: 22px;
  display: inline-block;
  position: absolute;
  left: -3px;
  top: -3px; }

.rankingPrice {
  font-weight: bold; }

.item .reviewRateStar {
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
  margin: 0 0.5px; }

.item .reviewCount {
  display: none; }

.itemDetail {
  text-align: left; }

.item span {
  font-weight: bold; }

.btn {
  display: inline-block;
  padding: 10px;
  width: 80%;
  box-sizing: border-box; }

.pager {
  margin-top: 20px;
  display: inline-block; }

.pager li {
  display: inline-block; }

.pager span {
  display: inline-block;
  color: #333333;
  text-decoration: none;
  margin-right: 10px;
  background: #e8e8e8;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 15px;
  font-size: 14px; }

.pager span.btn-next,
.pager span.btn-prev {
  border-radius: 6px; }

.pager span.current {
  background-color: #333;
  color: #FFF;
  border-radius: 50%; }

/***************************************

	  content

***************************************/
#main-content {
  margin: 10px 0; }

.heading {
  font-size: 16px; }

/***************************************

	  top

***************************************/
.index-section {
  margin-bottom: 30px; }

.recommendHeading,
.newHeading,
.rankingHeading {
  background: #222;
  color: #fff;
  padding: 6px 0; }

.itemListReview {
  margin: 10px auto 40px; }

.itemListReview li {
  width: 100%;
  margin: 0;
  display: block;
  border-bottom: 1px solid #ccc; }

.itemListReview a {
  display: block;
  padding: 10px;
  box-sizing: border-box; }

.itemListReview .itemBox {
  display: table; }

.itemListReview .itemImg,
.itemListReview .itemDetail {
  display: table-cell;
  vertical-align: top; }

.itemListReview .itemDetail {
  padding-left: 5px; }

.itemListReview .itemImg {
  width: 80px; }

.itemImg img {
  height: auto; }

.reviewBox {
  margin-top: 8px;
  font-size: 12px;
  color: #666;
  background: #eee;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; }

.reviewNullText {
  text-align: center;
  padding: 32px 0 48px; }

.catlistHeading {
  text-align: left;
  padding: 0 0 10px 10px; }

.catMenu a {
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  border-top: 1px dotted #666; }

.catMenu li:last-child a {
  border-bottom: 1px dotted #666; }

/***************************************

	  category

***************************************/
#topicPath,
.searchPath {
  background: #eee;
  text-align: left;
  padding: 10px;
  font-size: 11px;
  margin-bottom: 10px;
  word-wrap: break-word; }

#topicPath,
#topicPath a,
.searchPath {
  color: #666; }

#topicPath li {
  display: inline; }

.moreBtn a {
  display: inline-block;
  padding: 10px;
  width: 80%;
  border-radius: 6px;
  border: 3px solid #ccc; }

/***************************************

	  利用案内/特定商取引/個人情報保護ポリシー

***************************************/
h3.catTit {
  text-align: left;
  font-size: 17px;
  margin: 20px 10px 25px; }

.editContent {
  margin: 0 10px 24px;
  text-align: left; }

.editContent h4 {
  font-size: 14px;
  margin-bottom: 20px; }

#lawList th {
  font-size: 14px; }

#lawList td {
  font-size: 13px;
  margin-bottom: 30px;
  padding: 15px 0 30px; }

.editArea {
  font-size: 12px;
  text-align: left; }

.editArea pre {
  margin: 10px;
  white-space: pre-wrap; }

/***************************************

	  商品レビュー

***************************************/
/* レビューの星 */
.reviewRate {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  margin: 3px 0 8px -1px; }

.reviewRateStar {
  display: inline-block;
  width: 21px;
  height: 21px;
  text-indent: -9999em;
  cursor: pointer;
  background: no-repeat center center;
  background-size: 21px 21px;
  margin: 0 1px; }

.starOn {
  background-image: url(/smartphone/images/star_on.png); }

.starOff {
  background-image: url(/smartphone/images/star_off.png); }

.starHalf {
  background-image: url(/smartphone/images/star_half.png); }

/* レビューを書く */
.reviewContent {
  text-align: left;
  margin: 0 10px 24px; }

.reviewForm dl {
  margin-bottom: 10px; }

.reviewForm dd {
  margin-bottom: 15px; }

.reviewForm dt {
  margin-bottom: 4px;
  font-weight: bold; }

.reviewForm dl input {
  padding: 6px 0;
  width: 99%; }

.reviewForm dl textarea {
  padding: 6px 0;
  width: 99%; }

.reviewForm .reviewRateStar {
  width: 43px;
  height: 43px;
  background-size: 43px 43px; }

.reviewContent .required {
  background: #C00;
  color: white;
  display: inline-block;
  font-size: 11px;
  margin-left: 8px;
  padding: 1px 3px;
  font-weight: normal; }

.reviewItem {
  margin: 16px auto;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background: #eee;
  box-sizing: border-box;
  display: table; }

.reviewItemUnit {
  display: table-cell;
  vertical-align: middle;
  text-align: left; }

.reviewItemName {
  padding-left: 8px;
  font-size: 13px; }

.reviewItemImg {
  width: 80px; }

.reviewItemImg img {
  border: solid 1px #ccc;
  box-sizing: border-box;
  max-width: 100%;
  height: auto; }

.errorTxt {
  color: #cc0000;
  margin-bottom: 5px; }

.captionTxt {
  color: #888;
  text-align: right;
  font-size: 12px; }

.reviewText {
  border: 1px solid #808080;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 120px; }

.btnPost {
  background: #999;
  text-align: center;
  color: #fff !important;
  width: 100%;
  padding: 15px 0;
  font-size: 16px; }

#review #back .btn {
  width: auto;
  font-size: 13px;
  padding: 10px 30px;
  background: #eee; }

/***************************************

	  お知らせ

***************************************/
.newsHeading {
  background: #222;
  color: #fff;
  padding: 6px 0; }

.newsList {
  margin: 15px; }

.newsList li {
  text-align: left;
  margin-bottom: 5px; }

.newsDate,
.newsTitle {
  vertical-align: top;
  display: table-cell; }

.newsDate {
  color: #999;
  padding: 2px 5px 2px 0px;
  white-space: nowrap;
  font-size: 10px; }

.newsTitle {
  font-size: 12px; }

.newsIndexLink {
  margin-bottom: 10px; }

/* お知らせ詳細 */
.newsMain {
  padding: 15px;
  text-align: left; }

.newsMain .newsMainInfo {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
  text-align: right; }

.newsMain .newsMainTitle {
  font-size: 14px;
  margin-bottom: 10px; }

.newsMain .newsMainWrap {
  margin-bottom: 30px;
  border-top: dotted 1px #999;
  border-bottom: dotted 1px #999; }

.newsMain .newsMainContent {
  font-size: 12px;
  padding: 20px 10px 50px; }

.newsMain .newsMainPager {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden; }

.newsMain .newsMainPager li {
  text-align: center; }

.newsMain .newsMainPager a {
  display: inline-block;
  background: #e5e5e5;
  color: #333;
  width: 25%;
  white-space: nowrap; }

.newsMain .newsMainPager a.btnPrev {
  float: left; }

.newsMain .newsMainPager a.btnNext {
  float: right; }

.newsMain .newsIndexBtn a {
  background: #333;
  color: #fff;
  width: 100%;
  text-align: center; }

/* お知らせ一覧 */
.newsListAll {
  margin-bottom: 30px; }

.newsListAll .newsListContent li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: dotted 1px #999; }

.newsListAll .newsDate,
.newsListAll .newsTitle {
  display: block; }

.newsListAll .newsDate {
  font-size: 12px; }

.newsListAll .newsTitle {
  font-size: 14px; }

.newsTitle {
  font-size: 12px; }

.newsListPager {
  margin: 0 auto;
  font-size: 12px; }

/***************************************

	  footer

***************************************/
#footer-content {
  background: #2E3739;
  font-size: 12px;
  padding: 30px 10px 10px; }

.footerNav {
  margin-bottom: 20px;
  padding: 0 35px; }

.footerNav-link {
  font-size: 10px;
  display: inline-block;
  padding: 4px; }

.footerNav-link a {
  text-decoration: none;
  font-size: 11px;
  color: #fff; }

.btn-switch {
  background: #fff;
  color: #666;
  width: 60%; }

.copyright {
  font-size: 10px;
  color: #fff; }

.footerCTA {
  border-top: 1px solid #eee;
  padding: 30px 0;
  background: #fff;
  font-weight: bold; }
  .footerCTA p {
    text-align: center; }
  .footerCTA .text1 {
    font-size: 13px; }
  .footerCTA .text2 {
    font-size: 42px;
    font-family: Century Gothic,Arial,sans-serif; }
  .footerCTA .text3 {
    font-size: 12px; }
  .footerCTA .text4 a {
    display: block;
    width: 70%;
    margin: 12px auto 0;
    padding: 10px;
    text-decoration: none;
    background: #2E3739;
    color: #fff; }

.footerMiddle {
  padding: 20px 0;
  background: #FFFEEE; }
  .footerMiddle p {
    text-align: center;
    font-size: 13px;
    font-weight: bold; }
  .footerMiddle .icon {
    margin-top: 10px; }
  .footerMiddle a {
    margin-left: 10px; }
    .footerMiddle a:first-child {
      margin-left: 0; }

.footerBottom {
  padding: 25px 0;
  background: #F7F7F7; }
  .footerBottom .image {
    width: 250px;
    margin: auto; }
  .footerBottom .text {
    line-height: 1.6;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    color: #333; }

.pageTop {
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-align: right; }
  .pageTop a {
    display: inline-block;
    position: relative;
    border-radius: 3px;
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    background: #eee; }
    .pageTop a:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 8px;
      height: 8px;
      margin: auto;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      transform: rotate(45deg); }
    .pageTop a:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 12px;
      bottom: 0;
      right: 0;
      left: 0;
      width: 8px;
      height: 8px;
      margin: auto;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      transform: rotate(45deg); }

/*****************************************************

	全ての要素をリセット
	各ブラウザの表示を統一するための設定です

*****************************************************/
.textTitle {
  text-align: center;
  font-size: 34px;
  color: #2E3739; }

.textSubTitle {
  display: inline-block;
  position: relative;
  height: 28px;
  text-align: center;
  line-height: 28px;
  padding: 0 25px;
  margin-top: 15px;
  background: #D8AF1C;
  font-size: 14px;
  color: #fff; }

.textSubTitle::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -14px;
  width: 0;
  height: 0;
  margin: auto;
  border-right: 14px solid #D8AF1C;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent; }

.textSubTitle::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -14px;
  width: 0;
  height: 0;
  margin: auto;
  border-left: 14px solid #D8AF1C;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent; }

.topNav ul {
  display: flex; }
  .topNav ul li {
    display: flex;
    flex: 1; }
  .topNav ul a {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0 5px;
    text-decoration: none;
    background: #2E3739;
    font-size: 10px;
    color: #fff; }
    .topNav ul a img {
      width: 25px;
      margin-bottom: 7px; }
  .topNav ul li:last-child a img {
    width: 34px; }
  .topNav ul li:nth-child(n+2) {
    border-left: 1px solid #fff; }

.indexNews {
  border-bottom: 1px solid #E5E5E5; }

.newsHeadingTop {
  font-size: 21px;
  padding: 15px 15px 0;
  text-align: left;
  background: none;
  color: #222; }

.newsDate,
.newsTitle {
  display: block;
  font-size: 11px; }

.newsDate {
  font-weight: bold;
  color: #2E3739; }

.reason {
  padding: 60px 15px;
  border-bottom: 1px solid #E5E5E5;
  text-align: center; }

.reasonList li {
  margin-top: 40px; }

.reasonList dt {
  height: 54px;
  line-height: 54px;
  border-radius: 10px 10px 0 0;
  background: #2E3739;
  font-size: 32px;
  color: #fff; }

.reasonList dd {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  border-bottom: 2px solid #E2E2E2;
  border-right: 2px solid #E2E2E2;
  border-left: 2px solid #E2E2E2; }
  .reasonList dd p {
    text-align: center; }

.reasonList .text1 {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: bold; }

.reasonList .text2 {
  margin-top: 12px;
  line-height: 1.8;
  font-size: 13px; }

.detailButton {
  text-align: center; }

.detailButton a {
  display: block;
  height: 42px;
  margin-top: 50px;
  line-height: 42px;
  text-decoration: none;
  border-radius: 30px;
  background: #5B8D26;
  font-size: 15px;
  font-weight: bold;
  color: #fff; }

.banner {
  padding: 30px 15px;
  border-bottom: 1px solid #E5E5E5; }

.banner .bannerLeft, .banner .bannerRight {
  width: 50%;
  box-sizing: border-box; }

.banner .banner1 {
  display: flex;
  border: 1px solid #5B8D26; }

.banner .banner1 .bannerRight {
  position: relative;
  padding: 20px 20px 0;
  background: #5B8D26;
  color: #fff; }

.banner .banner1 .bannerRight .text1 {
  line-height: 1.5;
  font-size: 12px; }

.banner .banner1 .bannerRight .text2 {
  margin-top: 10px;
  letter-spacing: 2px;
  font-size: 10px; }

.banner .banner1 .bannerRight .text3 {
  margin-top: 10px;
  text-align: center; }

.banner .banner1 .bannerRight .text3 a {
  display: block;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  background: #fff;
  color: #5B8D26;
  font-size: 10px; }
  .banner .banner1 .bannerRight .text3 a:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    width: 6px;
    height: 6px;
    margin: auto;
    border-top: 1px solid #5B8D26;
    border-right: 1px solid #5B8D26;
    transform: rotate(45deg); }

.banner .banner1 .bannerRight .text3 a:hover {
  opacity: 0.7; }

.banner .banner1 .bannerRight .image {
  position: absolute;
  top: 14%;
  right: 10%;
  width: 44px; }

.banner .banner2 {
  margin-top: 20px;
  display: flex;
  border: 1px solid #2E3739; }

.banner .banner2 .bannerLeft {
  padding: 25px 25px 0;
  line-height: 1.5;
  background: #F7F7F7;
  font-size: 12px;
  font-weight: bold;
  color: #2E3739; }

.banner .banner2 .bannerLeft .small {
  display: inline-block;
  line-height: 1.2;
  font-size: 11px; }

.banner .banner2 .bannerLeft .red {
  font-size: 33px;
  color: #E34545; }

.banner .banner2 .bannerLeft .pt {
  font-size: 13px;
  color: #E34545; }

.banner .banner2 .bannerRight {
  padding: 20px 20px 20px;
  background: #2E3739;
  color: #fff; }

.banner .banner2 .bannerRight .text1 {
  line-height: 1.5;
  text-align: center;
  font-size: 12px; }

.banner .banner2 .bannerRight .text2 {
  margin-top: 10px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 10px; }

.banner .banner2 .bannerRight .text3 {
  margin-top: 10px;
  text-align: center; }

.banner .banner2 .bannerRight .text3 a {
  display: block;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-decoration: none;
  background: #fff;
  color: #2E3739;
  font-size: 10px; }
  .banner .banner2 .bannerRight .text3 a:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    width: 6px;
    height: 6px;
    margin: auto;
    border-top: 1px solid #5B8D26;
    border-right: 1px solid #5B8D26;
    transform: rotate(45deg); }

.banner .banner1 .bannerRight .text3 a:hover {
  opacity: 0.7; }

.category {
  padding: 60px 15px;
  border-bottom: 1px solid #E5E5E5; }

.category .categoryList {
  margin-top: 60px; }
  .category .categoryList li a {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 20px; }
    .category .categoryList li a p {
      width: 50%; }

.category .categoryList .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E2E2E2;
  background: #F7F7F7;
  text-align: center;
  font-size: 16px;
  font-weight: bold; }

.ranking {
  padding: 60px 15px; }
  .ranking .rankList {
    margin-top: 40px;
    text-align: left; }
    .ranking .rankList li {
      display: flex;
      margin-top: 20px; }
    .ranking .rankList .rankDetail em {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      border: 2px solid #2E3739;
      font-family: Arial,sans-serif;
      font-size: 15px;
      font-weight: bold;
      color: #2E3739; }
    .ranking .rankList #rank1 .rankDetail em {
      background: #D8AF1C;
      border-color: #D8AF1C;
      color: #fff; }
    .ranking .rankList #rank2 .rankDetail em {
      background: #A19F98;
      border-color: #A19F98;
      color: #fff; }
    .ranking .rankList #rank3 .rankDetail em {
      background: #D17335;
      border-color: #D17335;
      color: #fff; }
    .ranking .rankList .rankImg, .ranking .rankList .rankDetail {
      width: 50%; }
    .ranking .rankList .rankImg {
      padding-right: 15px; }
    .ranking .rankList .rankName {
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #333; }
    .ranking .rankList .rankPrice {
      font-family: Arial,sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: #E22828; }
    .ranking .rankList .free {
      display: inline-block;
      margin: 10px 0;
      padding: 5px;
      font-size: 12px;
      font-weight: bold;
      background: #E22828;
      color: #fff; }
    .ranking .rankList .tax {
      font-size: 13px; }

.search {
  padding: 10px 15px 60px; }
  .search .heading {
    display: flex;
    align-items: center;
    margin: 50px auto 40px;
    padding: 0 40px;
    font-size: 18px;
    font-weight: bold; }
  .search .heading:before,
  .search .heading:after {
    content: "";
    flex-grow: 1;
    border-top: 1px solid #2E3739; }
  .search .heading:before {
    margin-right: 1rem; }
  .search .heading:after {
    margin-left: 1rem; }
  .search .typeList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -7px; }
    .search .typeList li {
      width: 50%;
      padding: 7px;
      box-sizing: border-box; }
    .search .typeList a {
      display: inline-block;
      text-decoration: none; }
      .search .typeList a:hover {
        opacity: 0.7; }
    .search .typeList .txt {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #F17070;
      font-size: 16px;
      font-weight: bold;
      color: #fff; }
      .search .typeList .txt.type2 {
        background: #B77C17; }
      .search .typeList .txt.type3 {
        background: #378DC7; }
      .search .typeList .txt.type4 {
        background: #BA4697; }
      .search .typeList .txt.type5 {
        background: #5B8D26; }
  .search .links .link1 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    text-decoration: none;
    border: 2px solid #E2E2E2;
    border-radius: 5px;
    font-size: 17px;
    color: #000; }
    .search .links .link1 a p:first-child {
      flex: 1; }
    .search .links .link1 a span {
      font-size: 10px;
      color: #E34545; }
    .search .links .link1 a img {
      width: 50px;
      margin: 0 0 0 10px; }
    .search .links .link1 a:hover {
      opacity: 0.7; }
  .search .links .link2 {
    margin-top: 15px; }
    .search .links .link2 a {
      display: block;
      box-sizing: border-box;
      position: relative;
      padding: 20px;
      text-align: left;
      text-decoration: none;
      border: 2px solid #E2E2E2;
      border-radius: 5px;
      font-size: 15px;
      color: #000; }
      .search .links .link2 a .cont1 {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #2E3739; }
      .search .links .link2 a .text1 {
        display: inline-block;
        font-size: 19px; }
      .search .links .link2 a .text2 {
        font-size: 11px;
        color: #E34545; }
      .search .links .link2 a .text3 {
        font-size: 10px; }
      .search .links .link2 a .img1 {
        position: relative;
        right: 10px;
        width: 40px; }
      .search .links .link2 a .img2 {
        width: 40px;
        margin-left: 8px; }
      .search .links .link2 a .img3 {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 80px;
        right: 15px;
        margin: auto; }
  .search .brandList {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin: 50px -8px 0; }
    .search .brandList li {
      box-sizing: border-box;
      width: 50%;
      padding: 7px 25px; }
    .search .brandList a {
      display: inline-block;
      text-decoration: none;
      color: #333; }
    .search .brandList .image {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 250px; }
    .search .brandList .txt {
      text-align: center;
      font-size: 16px;
      font-weight: bold; }
  .search .NETcontentList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -7px; }
    .search .NETcontentList li:first-child {
      width: 100%; }
      .search .NETcontentList li:first-child a {
        width: 100%; }
    .search .NETcontentList li {
      box-sizing: border-box;
      padding: 7px;
      width: 50%; }
      .search .NETcontentList li a {
        display: inline-block;
        width: 100%;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        background: #F7F7F7;
        border: 2px solid #E2E2E2;
        border-radius: 5px;
        font-size: 16px;
        color: #000; }
        .search .NETcontentList li a:hover {
          opacity: 0.7; }

.choose {
  padding: 60px 0 0;
  background: #F7F7F7; }
  .choose .riceTableWrap {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin: 50px 15px 5px;
    padding-bottom: 20px; }
    .choose .riceTableWrap::-webkit-scrollbar {
      height: 6px; }
    .choose .riceTableWrap::-webkit-scrollbar-track {
      border-radius: 6px;
      background: #eee; }
    .choose .riceTableWrap::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #666; }
  .choose .scrollText {
    padding: 0 15px;
    font-size: 12px;
    color: #888; }
    .choose .scrollText img {
      width: 19px;
      margin-right: 5px; }
  .choose .riceTable {
    width: 100%;
    table-layout: fixed; }
    .choose .riceTable colgroup col:first-child {
      width: 116px; }
    .choose .riceTable colgroup col {
      width: 135px; }
    .choose .riceTable .row1 th {
      padding: 10px;
      text-align: center;
      box-sizing: border-box;
      font-size: 12px; }
    .choose .riceTable tr th:first-child {
      font-size: 12px; }
    .choose .riceTable th, .choose .riceTable td {
      padding: 4px 4px;
      border: 1px solid #ccc;
      vertical-align: middle; }
    .choose .riceTable th {
      background: #FAF8DF; }
    .choose .riceTable td {
      text-align: center;
      background: #fff; }
    .choose .riceTable .comment {
      margin-top: 3px;
      text-align: center;
      font-size: 10px;
      color: #2E3739; }
    .choose .riceTable .detailButton {
      display: block;
      padding: 4px;
      text-decoration: none;
      border: 1px solid #B7B7B7;
      border-radius: 8px;
      background: #F7F7F7;
      font-size: 12px;
      font-weight: bold;
      color: #2E3739; }
      .choose .riceTable .detailButton:hover {
        opacity: 0.7; }
    .choose .riceTable .riceIcon, .choose .riceTable .rice1, .choose .riceTable .rice1-5, .choose .riceTable .rice2, .choose .riceTable .rice2-5, .choose .riceTable .rice3, .choose .riceTable .rice3-5, .choose .riceTable .rice4, .choose .riceTable .rice4-5, .choose .riceTable .rice5 {
      display: block;
      width: 76px;
      height: 20px;
      margin: auto;
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png");
      background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,no-repeat;
      background-position: 0 0, 25% 0, 50% 0, 75% 0, 100% 0;
      background-size: 13px; }
    .choose .riceTable .rice1 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice1-5 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice2 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice2-5 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice3 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice3-5 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice4 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png"); }
    .choose .riceTable .rice4-5 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png"); }
    .choose .riceTable .rice5 {
      background-image: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"), url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png"); }
  .choose .riceGraph {
    margin-top: 30px; }

.about {
  padding: 60px 0; }

/* CSS Document */
.currentName {
  text-align: left;
  font-size: 18px;
  padding: 0 10px;
  margin-bottom: 20px; }

.no-resultWrap {
  padding: 32px 0 64px; }

.resultPath:nth-child(n + 2)::before {
  content: "、"; }

#catNarrowdown h3 span {
  padding-right: 30px;
  background: url("/smartphone/images/template/5/icon-close.png") no-repeat right center;
  background-size: 26px 26px; }

#catNarrowdown.subCatOpen h3 span {
  background-image: url("/smartphone/images/template/5/icon-open.png"); }

#catNarrowdown {
  margin-bottom: 15px; }

#catNarrowdown a {
  display: block;
  background: #5E5E5E;
  padding: 15px 0;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  box-sizing: border-box; }

#child_category_list a {
  padding: 10px;
  border-color: #999;
  font-size: 12px; }

.itemHead {
  padding: 10px 10px 0;
  border-top: 3px solid #ccc; }

.resultCount {
  text-align: left;
  padding: 0 10px 8px;
  font-size: 12px; }

.sortOrder {
  text-align: center;
  letter-spacing: -0.4em; }

.sortOrder li {
  display: inline-block;
  padding: 2px 0;
  box-sizing: border-box;
  width: 25%;
  letter-spacing: normal;
  font-size: 12px;
  border-right: 1px solid #ccc; }

.sortOrder li a {
  display: block; }

.sortOrder li:last-child {
  border-right: none; }

.searchHead {
  display: table;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc; }

.viewCount, .viewSelect {
  display: table-cell;
  font-size: 13px;
  width: 50%;
  text-align: center; }

select[name="item_list_limit"] {
  width: 80px;
  padding: 4px 6px; }

/* リスト形式 */
#thumb_images {
  padding-bottom: 90px; }

.listBox #list_item li {
  display: table;
  width: 100%;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 10px;
  margin: 0 0 10px 0; }

.listBox #list_item .floatL,
.listBox #list_item .itemDetail {
  display: table-cell;
  vertical-align: top;
  padding-left: 5px; }

#list_item .itemDetail .price {
  font-size: 13px;
  color: #E22828; }
  #list_item .itemDetail .price em {
    font-size: 18px;
    font-weight: bold; }

.listBox #list_item .floatL {
  width: 80px; }

.reviewCount {
  display: inline;
  color: #999; }

.reviewRateStar {
  width: 15px;
  height: 15px;
  background-size: 15px 15px;
  margin: 0 0.5px; }

.listGrid .reviewCount {
  display: none; }

.matomegai {
  display: inline-block;
  margin-top: 8px; }

.matomegai a {
  display: inline-block;
  border: 1px solid #54B3DE;
  border-radius: 4px;
  color: #54B3DE;
  font-size: 10px;
  padding: 4px 6px; }

.listBox .matomegai {
  margin-left: 5px; }

/* 詳細検索 */
.searchForm {
  border-top: 1px solid #dcdcdc;
  background: #fafafa;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: -60px; }

.searchTitle {
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px dotted #dcdcdc; }

.searchFormWrap {
  padding: 10px; }

.searchBtnWrap {
  text-align: center;
  padding-bottom: 20px; }

.searchBtn {
  background: #54b3de;
  text-align: center;
  color: #fff !important;
  margin: 15px auto; }

.searchItem {
  width: 100%;
  display: table; }

.searchItem dt,
.searchItem dd {
  display: table-cell;
  padding: 8px 0;
  font-size: 14px; }

.searchItem dt {
  width: 30%; }

.searchItem select {
  background-color: #fff; }

input[type="text"].inputPrice {
  width: 5em;
  margin-right: 4px; }

/* CSS Document */
.detailPage {
  /* 商品名 */
  /* 商品画像 */
  /* 価格 */
  /* オプション */
  /* 数量 */
  /* 説明文 */
  /* 追加商品画像 */
  /* カートに入れるボタン */
  /* 商品グループ名を非表示 */
  /* レビュー */
  /* SNS */
  /********************************** 以下はオプション使用時のスタイルになります */
  /* 会員グループ別価格 */
  /* 予約販売 */
  /* 定期購入 */
  /* 定期購入 */ }
  .detailPage .topItemInfo.itemSection {
    border-bottom: none; }
  .detailPage .topItemInfo .reviewStarWrap {
    text-align: left; }
    .detailPage .topItemInfo .reviewStarWrap .reviewStar {
      margin-top: 0; }
  .detailPage .itemName {
    text-align: left;
    margin-bottom: 10px;
    padding-bottom: 10px;
    line-height: 1.6;
    border-bottom: 1px solid #333;
    word-wrap: break-word;
    font-weight: bold;
    font-size: 18px; }
  .detailPage .originalCode {
    text-align: left;
    font-size: 12px; }
  .detailPage .itemSection {
    border-bottom: 1px solid #ddd;
    padding: 10px 10px 10px; }
  .detailPage .itemImg {
    border: none; }
  .detailPage .imgWrap {
    height: 40vh;
    margin-bottom: 10px; }
    .detailPage .imgWrap a {
      display: block;
      position: relative;
      width: auto;
      height: 100%; }
      .detailPage .imgWrap a img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        max-height: 100%;
        margin: auto; }
  .detailPage #thumb_images {
    padding: 0;
    letter-spacing: -0.4em;
    text-align: left; }
  .detailPage #thumb_images li {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 20%;
    height: 50px;
    line-height: inherit;
    box-sizing: border-box;
    position: relative; }
    .detailPage #thumb_images li:nth-child(n+6) {
      margin-top: 10px; }
  .detailPage #thumb_images li img {
    width: auto;
    max-height: 50px;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  .detailPage .heading {
    text-align: left;
    margin-bottom: 20px; }
  .detailPage .itemInfo {
    text-align: left; }
    .detailPage .itemInfo .itemDetail {
      margin: 15px 0;
      font-size: 14px; }
    .detailPage .itemInfo .text1 {
      margin-top: 10px;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      color: #2E3739; }
      .detailPage .itemInfo .text1 img {
        width: 24px;
        margin-right: 8px; }
    .detailPage .itemInfo .free {
      margin-left: 10px;
      padding: 2px 6px;
      background: #E22828;
      font-size: 12px;
      color: #fff; }
  .detailPage .itemInfo .reviewCount {
    vertical-align: middle; }
  .detailPage .itemInfo .reviewCount a {
    text-decoration: underline; }
  .detailPage .itemInfo p {
    vertical-align: bottom; }
  .detailPage .markedPrice {
    font-size: 12px;
    color: #999; }
  .detailPage .price {
    font-weight: bold;
    display: inline-block;
    color: #E22828; }
  .detailPage #taxPrice {
    font-size: 30px; }
  .detailPage .shopPoint {
    color: #666;
    font-size: 13px; }
  .detailPage .itemAmount {
    overflow: hidden; }
  .detailPage .itemOption {
    text-align: left; }
  .detailPage select {
    margin: 5px auto 8px; }
  .detailPage .itemOption select {
    margin-bottom: 12px; }
  .detailPage .amount {
    display: table;
    float: left; }
  .detailPage .amount dt,
  .detailPage .amount dd {
    display: table-cell;
    padding: 0 5px; }
  .detailPage input[name="amount"] {
    width: 6em; }
  .detailPage .stock {
    float: right; }
  .detailPage .stockBtn {
    display: inline-block;
    width: 100%;
    background: #999; }
  .detailPage .soldout {
    color: #cc0000;
    font-size: 18px;
    padding: 10px 0; }
  .detailPage .stockBtn:link {
    color: #fff; }
  .detailPage .content {
    font-size: 14px;
    text-align: left; }
  .detailPage .more-content {
    margin-top: 20px; }
  .detailPage #add_img {
    margin-top: 10px; }
  .detailPage #add_img li {
    margin-bottom: 10px; }
  .detailPage #add_img img {
    padding: 5px 0; }
  .detailPage #add_img p {
    text-align: left; }
  .detailPage .btnCart, .detailPage .btnRestock {
    background: #E34545;
    color: #fff;
    width: 100%;
    font-size: 17px;
    padding: 15px 0;
    font-weight: bold; }
  .detailPage .contactLink {
    text-align: center;
    font-size: 12px;
    margin-top: 10px; }
  .detailPage .contactLink img {
    width: 20px;
    height: 20px; }
  .detailPage .review {
    margin-top: 10px; }
  .detailPage #group h4 {
    display: none; }
  .detailPage .itemReview {
    text-align: left;
    padding-top: 50px; }
  .detailPage .reviewWrap {
    padding: 10px 10px 20px 10px; }
  .detailPage .reviewAverage {
    font-weight: bold;
    font-size: 19px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px; }
  .detailPage .reviewCount {
    color: #999;
    display: inline-block;
    vertical-align: middle; }
  .detailPage .list_review {
    margin: 16px auto -1px; }
  .detailPage .btnReview {
    display: inline-block;
    width: 100%;
    text-align: center; }
  .detailPage .list_review {
    border: 1px solid #ccc; }
  .detailPage .list_review li {
    border-bottom: 1px dotted #ccc;
    padding: 16px; }
  .detailPage .list_review li:last-child {
    border-bottom: none; }
  .detailPage .reviewInfo {
    overflow: hidden;
    margin-bottom: 4px; }
  .detailPage .reviewName {
    float: left; }
  .detailPage .reviewDate {
    color: #999;
    float: right;
    font-size: 13px; }
  .detailPage .list_review .reviewRate {
    margin-top: 0; }
  .detailPage .list_review .reviewRateStar {
    width: 15px;
    height: 15px;
    background-size: 15px 15px; }
  .detailPage .reviewComment {
    font-size: 13px;
    color: #666;
    background: #eee;
    padding: 10px; }
  .detailPage .reviewMore .btnMore {
    border: 1px solid #ccc;
    border-top: none;
    text-align: center;
    border-radius: 0;
    width: 100%;
    padding: 14px 0;
    margin-left: 0;
    color: #666;
    font-weight: bold; }
  .detailPage .reviewBtn .btnReview {
    margin-top: 16px;
    background: #999;
    color: #fff;
    padding: 12px 0;
    margin-left: 0; }
  .detailPage .reviewNullText {
    text-align: center;
    padding: 16px 0 24px; }
  .detailPage .snsBtn li {
    display: inline-block;
    padding: 0 5px; }
  .detailPage .snsBtn iframe {
    vertical-align: middle; }
  .detailPage .snsBtn .snsFacebook iframe {
    width: 85px !important; }
  .detailPage .memberPrice {
    color: #e73656; }
  .detailPage .reserveInfo {
    margin: 16px 0;
    font-size: 13px;
    background: #f2f2f2;
    padding: 12px; }
  .detailPage .reserveTitle {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 4px;
    color: #e73656; }
  .detailPage .reserveInfo p {
    text-align: left; }
  .detailPage .reserveSaleDate {
    margin-bottom: 4px; }
  .detailPage .reserveSaleText, .detailPage .reserveText {
    font-size: 12px;
    color: #666; }
  .detailPage .repeatTitle {
    color: #10a764;
    text-align: left;
    font-size: 15px;
    margin: 24px 0 16px; }
  .detailPage .repeatPrice {
    font-weight: bold; }
  .detailPage #rs_taxPrice {
    font-size: 19px; }
  .detailPage .repeatsaleWrap .shopp {
    margin: 2px 0;
    color: #666;
    font-size: 13px; }
  .detailPage .repeatPrice table {
    width: 100%;
    margin-bottom: 8px; }
  .detailPage .repeatPrice th, .detailPage .repeatPrice td {
    padding: 4px 8px;
    text-align: left;
    border: 1px solid #ccc; }
  .detailPage .repeatPrice th {
    background: #f2f2f2; }
  .detailPage #repeatGift {
    margin-top: 24px; }
  .detailPage #repeatGift .giftName {
    text-align: left;
    font-weight: bold;
    margin-bottom: 8px; }
  .detailPage #repeatGift .giftImg {
    display: inline-block;
    vertical-align: top;
    width: 30%; }
  .detailPage #repeatGift .giftImg img {
    max-width: 100%; }
  .detailPage #repeatGift .giftDetail {
    display: inline-block;
    text-align: left; }
  .detailPage .btnRepeat {
    background: #10a764;
    color: #fff;
    width: 100%;
    font-size: 17px;
    padding: 15px 0;
    font-weight: bold; }
  .detailPage .repeatTitle {
    color: #10a764;
    text-align: left;
    font-size: 15px;
    margin: 24px 0 16px; }
  .detailPage .repeatPrice {
    font-weight: bold; }
  .detailPage #rs_taxPrice {
    font-size: 19px; }
  .detailPage .repeatsaleWrap .shopp {
    margin: 2px 0;
    color: #666;
    font-size: 13px; }
  .detailPage .repeatPrice table {
    width: 100%;
    margin-bottom: 8px; }
  .detailPage .repeatPrice th, .detailPage .repeatPrice td {
    padding: 4px 8px;
    text-align: left;
    border: 1px solid #ccc; }
  .detailPage .repeatPrice th {
    background: #f2f2f2; }
  .detailPage #repeatGift {
    margin-top: 24px; }
  .detailPage #repeatGift .giftName {
    text-align: left;
    font-weight: bold;
    margin-bottom: 8px; }
  .detailPage #repeatGift .giftImg {
    display: inline-block;
    vertical-align: top;
    width: 30%; }
  .detailPage #repeatGift .giftImg img {
    max-width: 100%; }
  .detailPage #repeatGift .giftDetail {
    display: inline-block;
    text-align: left; }
  .detailPage .btnRepeat {
    background: #10a764;
    color: #fff;
    width: 100%;
    font-size: 17px;
    padding: 15px 0;
    font-weight: bold; }
  .detailPage .titleWrap {
    text-align: center; }
  .detailPage .textTitle {
    text-align: center;
    font-size: 34px;
    color: #2E3739; }
  .detailPage .textSubTitle {
    display: inline-block;
    position: relative;
    height: 28px;
    text-align: center;
    line-height: 28px;
    padding: 0 25px;
    margin-top: 15px;
    background: #D8AF1C;
    font-size: 14px;
    color: #fff; }
  .detailPage .textSubTitle::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -14px;
    width: 0;
    height: 0;
    margin: auto;
    border-right: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }
  .detailPage .textSubTitle::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -14px;
    width: 0;
    height: 0;
    margin: auto;
    border-left: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }
  .detailPage .reviewStarWrap {
    text-align: center; }
  .detailPage .reviewStar {
    display: inline-block;
    margin-top: 20px; }
  .detailPage .reviewSection {
    margin: 10px;
    padding: 20px 20px;
    border: 2px solid #D8AF1C;
    line-height: 1.8;
    letter-spacing: 1.2; }
    .detailPage .reviewSection .reviewHeading {
      text-align: center;
      margin: 10px -5px 20px;
      font-size: 18px;
      font-weight: bold;
      color: #D8AF1C; }
      .detailPage .reviewSection .reviewHeading:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 35px;
        vertical-align: middle;
        margin-right: 10px;
        background: #D8AF1C;
        transform: rotate(-16deg); }
      .detailPage .reviewSection .reviewHeading:after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 35px;
        vertical-align: middle;
        margin-left: 10px;
        background: #D8AF1C;
        transform: rotate(16deg); }
    .detailPage .reviewSection .balloon {
      position: relative;
      margin-top: 10px;
      padding: 10px 15px;
      text-align: left;
      border-radius: 10px;
      background: #F8F4E9;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
      color: #333; }
    .detailPage .reviewSection .balloonLeft:before {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -6px;
      left: -15px;
      width: 0;
      height: 0;
      border-right: 25px solid #F8F4E9;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      transform: rotate(-28deg); }
    .detailPage .reviewSection .balloonRight:after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: -6px;
      right: -15px;
      width: 0;
      height: 0;
      border-left: 25px solid #F8F4E9;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      transform: rotate(28deg); }
  .detailPage .storageSection {
    margin: 40px 10px 10px;
    padding: 20px 25px;
    text-align: left;
    border: 2px solid #E2E2E2; }
    .detailPage .storageSection .heading {
      font-size: 25px;
      font-weight: bold;
      color: #CC0000; }
    .detailPage .storageSection .text1 {
      margin: 10px 0 10px;
      font-size: 16px;
      font-weight: bold; }
    .detailPage .storageSection .text2 {
      font-size: 14px; }

.titleWrap .titleText {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 65px;
  height: 65px;
  margin: -32px auto 0;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  background: #5B8D26;
  color: #FFFEEE; }
  .titleWrap .titleText:hover {
    opacity: 0.7; }
  .titleWrap .titleText span {
    line-height: 0.8;
    font-size: 14px; }

.titleText2 {
  margin-top: 15px;
  padding: 0 10px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #5B8D26; }

.text {
  margin-top: 20px;
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
  color: #333; }

.fastSection {
  margin-top: 20px; }

.inexpensiveSection {
  margin-top: 30px; }
  .inexpensiveSection .text {
    margin: 20px; }

.safeSection {
  margin-top: 30px; }
  .safeSection .text {
    margin: 20px; }

.imageList {
  margin: 40px auto; }
  .imageList li {
    border: 2px solid #E2E2E2;
    margin: 10px; }
    .imageList li .textWrap {
      line-height: 1.8;
      padding: 15px 15px;
      text-align: left; }
      .imageList li .textWrap p {
        margin-top: 10px;
        font-size: 12px; }
    .imageList li .imageListTitle {
      font-size: 18px;
      font-weight: bold; }
      .imageList li .imageListTitle span {
        line-height: 1.2;
        vertical-align: middle; }
      .imageList li .imageListTitle .listTitle {
        display: block;
        margin-top: 10px; }
      .imageList li .imageListTitle .icon {
        display: inline-block;
        margin-right: 15px;
        padding: 5px 13px;
        border-radius: 5px;
        background: #B77C17;
        font-size: 13px;
        color: #fff; }
        .imageList li .imageListTitle .icon span {
          position: relative;
          top: -1px;
          margin-left: 5px;
          font-size: 20px; }

.inner {
  width: 100%;
  margin: auto; }

.title {
  margin: 15px 10px;
  text-align: center;
  font-size: 18px; }
  .title span {
    font-weight: bold; }

.fukuimaiList li {
  box-sizing: border-box;
  margin: 10px;
  padding: 30px 20px;
  border: 3px solid rgba(62, 38, 28, 0.2);
  border-radius: 4px; }
  .fukuimaiList li .name {
    margin: 0;
    font-family: "游明朝",serif;
    font-size: 27px;
    color: #3E261C; }
    .fukuimaiList li .name span {
      display: inline-block;
      position: relative;
      width: 68px;
      height: 22px;
      top: -5px;
      border-radius: 3px;
      text-align: center;
      line-height: 22px;
      margin-right: 5px;
      font-size: 11px;
      font-weight: bold;
      background: #3E261C;
      color: #fff; }
  .fukuimaiList li p {
    margin-top: 20px;
    font-size: 12px;
    color: #333; }

.ingredientText {
  margin: 40px 10px 30px;
  text-align: center;
  font-size: 16; }

.fukidasi {
  margin: 45px 0 23px;
  text-align: center;
  font-size: 16px;
  color: #B77C17; }
  .fukidasi:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 4px;
    width: 0;
    height: 20px;
    margin-right: 10px;
    border-right: 1px solid #B77C17;
    transform: rotate(-15deg); }
  .fukidasi:after {
    content: "";
    display: inline-block;
    position: relative;
    top: 4px;
    width: 0;
    height: 20px;
    margin-left: 10px;
    border-right: 1px solid #B77C17;
    transform: rotate(15deg); }

.ajiText {
  padding: 0 10px;
  text-align: center;
  font-size: 15px;
  color: #333; }
  .ajiText span {
    font-weight: bold;
    color: #CC0000; }

.ricePicture {
  display: flex;
  justify-content: space-between;
  margin-top: 25px; }
  .ricePicture p {
    width: 440px; }
    .ricePicture p img {
      width: 100%; }

.heading_border {
  display: flex;
  align-items: center;
  width: 300px;
  margin: 70px auto 50px;
  font-size: 18px;
  font-weight: bold; }

.heading_border:before,
.heading_border:after {
  content: "";
  flex-grow: 1;
  border-top: 1px solid; }

.heading_border:before {
  margin-right: 1rem; }

.heading_border:after {
  margin-left: 1rem; }

.riceField {
  margin-top: 30px;
  padding: 50px 20px;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/category/ricefield.png") no-repeat center; }
  .riceField .riceFieldInner {
    padding: 20px 10px;
    background: rgba(255, 255, 255, 0.85); }
  .riceField .text1 {
    text-align: center;
    font-family: "游明朝",serif;
    font-size: 26px;
    color: #333; }
  .riceField .text2 {
    margin-top: 12px;
    line-height: 1.9;
    text-align: center;
    font-size: 12px; }

.riceGraph {
  text-align: center; }
  .riceGraph img {
    width: 960px; }

.content1 .breadcrumb {
  margin: 20px 10px;
  text-align: left;
  font-size: 13px;
  color: #333; }
  .content1 .breadcrumb a {
    text-decoration: none;
    font-size: 13px;
    color: #333; }
    .content1 .breadcrumb a:hover {
      text-decoration: underline; }

.content1 .mainview {
  padding: 40px 10px;
  box-sizing: border-box;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content1/mainv.png") no-repeat top center;
  background-size: cover; }
  .content1 .mainview .text1, .content1 .mainview .text2, .content1 .mainview .text3, .content1 .mainview .text4 {
    text-align: center;
    margin: auto; }
  .content1 .mainview .text1 {
    font-size: 18px;
    font-weight: bold;
    color: #2E3739; }
  .content1 .mainview .text2 {
    font-size: 35px;
    font-weight: bold;
    color: #2E3739; }
  .content1 .mainview .text3 {
    font-size: 18px;
    color: #5B8D26; }
  .content1 .mainview .text4 {
    margin-top: 30px;
    line-height: 1.8;
    font-size: 13px;
    color: #2E3739; }
  .content1 .mainview .text5 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px; }
    .content1 .mainview .text5 a {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      width: 75px;
      height: 75px;
      margin: 0 7px;
      padding-bottom: 5px;
      border-radius: 50px;
      text-decoration: none;
      text-align: center;
      background: #5B8D26;
      color: #FFFEEE; }
      .content1 .mainview .text5 a:after {
        content: "";
        position: absolute;
        bottom: 10px;
        right: 0;
        left: 0;
        width: 8px;
        height: 8px;
        margin: auto;
        border-bottom: 1px solid #FFFEEE;
        border-right: 1px solid #FFFEEE;
        transform: rotate(45deg); }
      .content1 .mainview .text5 a:hover {
        opacity: 0.7; }
      .content1 .mainview .text5 a span {
        line-height: 0.8;
        font-size: 12px; }

.content1 .titleWrap .titleText {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 65px;
  height: 65px;
  margin: -32px auto 0;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  background: #5B8D26;
  color: #FFFEEE; }
  .content1 .titleWrap .titleText:hover {
    opacity: 0.7; }
  .content1 .titleWrap .titleText span {
    line-height: 0.8;
    font-size: 14px; }

.content1 .titleText2 {
  margin-top: 15px;
  padding: 0 10px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #5B8D26; }

.content1 .text {
  margin-top: 20px;
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
  color: #333; }

.content1 .fastSection {
  margin-top: 20px; }

.content1 .inexpensiveSection {
  margin-top: 30px; }
  .content1 .inexpensiveSection .text {
    margin: 20px; }

.content1 .safeSection {
  margin-top: 30px; }
  .content1 .safeSection .text {
    margin: 20px; }

.content1 .imageList {
  margin: 40px auto; }
  .content1 .imageList li {
    border: 2px solid #E2E2E2;
    margin: 10px; }
    .content1 .imageList li .textWrap {
      line-height: 1.8;
      padding: 15px 15px;
      text-align: left; }
      .content1 .imageList li .textWrap p {
        margin-top: 10px;
        font-size: 12px; }
    .content1 .imageList li .imageListTitle {
      font-size: 18px;
      font-weight: bold; }
      .content1 .imageList li .imageListTitle span {
        line-height: 1.2;
        vertical-align: middle; }
      .content1 .imageList li .imageListTitle .listTitle {
        display: block;
        margin-top: 10px; }
      .content1 .imageList li .imageListTitle .icon {
        display: inline-block;
        margin-right: 15px;
        padding: 5px 13px;
        border-radius: 5px;
        background: #B77C17;
        font-size: 13px;
        color: #fff; }
        .content1 .imageList li .imageListTitle .icon span {
          position: relative;
          top: -1px;
          margin-left: 5px;
          font-size: 20px; }

.content2 .breadcrumb {
  margin: 20px 10px;
  text-align: left;
  font-size: 13px;
  color: #333; }
  .content2 .breadcrumb a {
    text-decoration: none;
    font-size: 13px;
    color: #333; }
    .content2 .breadcrumb a:hover {
      text-decoration: underline; }

.content2 .mainview {
  padding: 40px 10px 70px;
  box-sizing: border-box;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content2/mainv.png") no-repeat top center;
  background-size: cover; }
  .content2 .mainview .text1, .content2 .mainview .text2, .content2 .mainview .text3, .content2 .mainview .text4 {
    text-align: center;
    margin: auto; }
  .content2 .mainview .text1 {
    font-size: 35px;
    font-weight: bold;
    color: #fff; }
  .content2 .mainview .text2 {
    margin-top: 5px;
    font-size: 16px;
    color: #5B8D26; }
  .content2 .mainview .text3 {
    margin-top: 20px;
    line-height: 1.8;
    text-shadow: 0 0 2px #000;
    font-size: 14px;
    color: #fff; }

.content2 .greetingSection {
  display: flex;
  padding: 5px 10px 40px;
  font-size: 17px;
  color: #333; }
  .content2 .greetingSection .picture img {
    width: 389px; }
  .content2 .greetingSection .greetingText {
    padding: 0 20px; }
    .content2 .greetingSection .greetingText .greetingHeading {
      margin-top: 0;
      font-family: "游明朝",serif;
      font-size: 22px;
      font-weight: bold; }
    .content2 .greetingSection .greetingText p {
      margin-top: 15px;
      font-size: 14px; }
    .content2 .greetingSection .greetingText .name {
      margin-top: 30px; }
      .content2 .greetingSection .greetingText .name span {
        margin-left: 50px; }
    .content2 .greetingSection .greetingText .thanks img {
      width: 150px; }

.content2 .staffSection {
  padding: 40px 0 65px;
  background: #F8F4E9; }
  .content2 .staffSection .staffSectionTitle {
    font-size: 26px;
    text-align: center;
    color: #584206; }
    .content2 .staffSection .staffSectionTitle:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      width: 0;
      height: 34px;
      margin-right: 20px;
      border-right: 1px solid #584206;
      transform: rotate(-15deg); }
    .content2 .staffSection .staffSectionTitle:after {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      width: 0;
      height: 34px;
      margin-left: 20px;
      border-right: 1px solid #584206;
      transform: rotate(15deg); }
  .content2 .staffSection .staffList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #584206;
    margin: 0 -12px 0; }
    .content2 .staffSection .staffList li {
      width: 35%;
      margin: 34px 12px 0; }
    .content2 .staffSection .staffList .picture img {
      width: 100%; }
    .content2 .staffSection .staffList .department {
      margin-top: 15px;
      text-align: center;
      font-size: 10px; }
    .content2 .staffSection .staffList .name {
      margin-top: 5px;
      text-align: center;
      font-size: 16px; }

.content2 .bannerSection {
  padding: 40px 10px 40px; }
  .content2 .bannerSection .bannerList {
    justify-content: space-between; }
    .content2 .bannerSection .bannerList li:nth-child(n+2) {
      margin-top: 20px; }
    .content2 .bannerSection .bannerList li img {
      width: 100%; }
    .content2 .bannerSection .bannerList li .text {
      margin-top: 10px;
      font-size: 15px;
      color: #2E3739; }

.content2 .infoSection {
  padding: 0 0 40px;
  background: #F8F4E9; }

.content2 .infoListWrap {
  padding: 10px; }

.content2 .infoList {
  text-align: left;
  font-size: 15px;
  color: #2E3739; }
  .content2 .infoList .heading {
    display: flex;
    align-items: center;
    margin: 60px auto 40px;
    padding: 0 25px;
    font-size: 28px;
    font-weight: bold; }
  .content2 .infoList .heading:before,
  .content2 .infoList .heading:after {
    content: "";
    flex-grow: 1;
    border-top: 1px solid; }
  .content2 .infoList .heading:before {
    margin-right: 1rem; }
  .content2 .infoList .heading:after {
    margin-left: 1rem; }
  .content2 .infoList dl {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #BEBEBE; }
    .content2 .infoList dl dt, .content2 .infoList dl dd {
      box-sizing: border-box;
      width: 50%;
      padding: 20px;
      border-bottom: 1px solid #BEBEBE; }

.content2 .contributionSection {
  padding: 40px 0 50px; }
  .content2 .contributionSection .textTitle {
    text-align: center;
    font-size: 34px;
    color: #2E3739; }
  .content2 .contributionSection .textSubTitle {
    display: inline-block;
    position: relative;
    height: 28px;
    line-height: 28px;
    padding: 0 25px;
    margin-top: 15px;
    background: #D8AF1C;
    font-size: 14px;
    color: #fff; }
  .content2 .contributionSection .textSubTitle::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -14px;
    width: 0;
    height: 0;
    margin: auto;
    border-right: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }
  .content2 .contributionSection .textSubTitle::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -14px;
    width: 0;
    height: 0;
    margin: auto;
    border-left: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }
  .content2 .contributionSection .contributionList {
    margin-top: 40px;
    color: #333; }
    .content2 .contributionSection .contributionList li {
      margin: 20px 10px;
      padding: 30px 30px;
      border: 1px solid #E2E2E2; }
    .content2 .contributionSection .contributionList .title {
      font-size: 18px;
      font-weight: bold; }
    .content2 .contributionSection .contributionList .text {
      margin-top: 20px;
      line-height: 1.8;
      font-size: 15px; }
    .content2 .contributionSection .contributionList .image {
      margin-top: 20px;
      margin-bottom: 30px; }
    .content2 .contributionSection .contributionList .imageWrap {
      display: flex;
      justify-content: space-between; }
      .content2 .contributionSection .contributionList .imageWrap .image {
        width: 49%; }
    .content2 .contributionSection .contributionList img {
      width: 100%; }

.content3 .breadcrumb {
  margin: 20px 10px;
  text-align: left;
  font-size: 13px;
  color: #333; }
  .content3 .breadcrumb a {
    text-decoration: none;
    font-size: 13px;
    color: #333; }
    .content3 .breadcrumb a:hover {
      text-decoration: underline; }

.content3 .mainview {
  padding: 90px 0 30px;
  box-sizing: border-box;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content3/mainv.png") no-repeat top center;
  background-size: cover; }
  .content3 .mainview .text1, .content3 .mainview .text2, .content3 .mainview .text3, .content3 .mainview .text4 {
    text-align: center;
    margin: auto; }
  .content3 .mainview .text1 {
    text-shadow: 0 0 2px #000;
    font-size: 40px;
    font-weight: bold;
    color: #fff; }
  .content3 .mainview .text2 {
    margin-top: 30px;
    line-height: 1.8;
    text-shadow: 0 0 2px #000;
    font-size: 14px;
    font-weight: bold;
    color: #fff; }

.content3 .reasonsSection {
  padding-bottom: 100px; }

.content3 .reasonsList {
  margin-top: 25px;
  color: #333; }
  .content3 .reasonsList li:nth-child(n+2) {
    margin-top: 30px; }
  .content3 .reasonsList li:nth-child(odd) {
    flex-direction: row; }
  .content3 .reasonsList li:nth-child(even) {
    flex-direction: row-reverse; }
  .content3 .reasonsList li .image img {
    width: 100%; }
  .content3 .reasonsList li .textWrap {
    padding: 20px 10px; }
  .content3 .reasonsList li .title {
    padding-bottom: 8px;
    line-height: 1.2;
    font-size: 28px;
    font-weight: bold; }
  .content3 .reasonsList li .text {
    margin-top: 25px;
    line-height: 1.6;
    font-size: 14px; }
  .content3 .reasonsList li .imageList {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; }

.content4 .breadcrumb {
  margin: 20px 10px;
  text-align: left;
  font-size: 13px;
  color: #333; }
  .content4 .breadcrumb a {
    text-decoration: none;
    font-size: 13px;
    color: #333; }
    .content4 .breadcrumb a:hover {
      text-decoration: underline; }

.content4 .mainview {
  padding: 80px 10px 80px;
  box-sizing: border-box;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content4/mainv.png") no-repeat top center; }
  .content4 .mainview .text1, .content4 .mainview .text2, .content4 .mainview .text3, .content4 .mainview .text4 {
    text-align: center;
    margin: auto; }
  .content4 .mainview .text1 {
    text-shadow: 0 0 2px #000;
    font-size: 18px;
    font-weight: bold;
    color: #fff; }
  .content4 .mainview .text2 {
    margin-top: 10px;
    text-shadow: 0 0 2px #000;
    font-size: 40px;
    font-weight: bold;
    color: #fff; }
  .content4 .mainview .text3 {
    margin-top: 10px;
    line-height: 1.8;
    text-shadow: 0 0 2px #000;
    font-size: 12px;
    font-weight: bold;
    color: #fff; }

.content4 .placeSection {
  padding: 10px 0 40px; }
  .content4 .placeSection .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #000; }
  .content4 .placeSection .placeImage {
    margin-top: 35px;
    display: flex;
    justify-content: space-between; }
  .content4 .placeSection .text {
    margin-top: 15px;
    padding: 10px;
    text-align: center;
    font-size: 15px;
    color: #333; }

.content4 .textTitle {
  text-align: center;
  font-size: 25px;
  color: #2E3739; }

.content4 .textSubTitle {
  display: inline-block;
  position: relative;
  height: 28px;
  line-height: 28px;
  padding: 0 25px;
  margin-top: 15px;
  background: #D8AF1C;
  font-size: 14px;
  color: #fff; }

.content4 .textSubTitle::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -14px;
  width: 0;
  height: 0;
  margin: auto;
  border-right: 14px solid #D8AF1C;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent; }

.content4 .textSubTitle::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -14px;
  width: 0;
  height: 0;
  margin: auto;
  border-left: 14px solid #D8AF1C;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent; }

.content4 .productSection {
  padding: 45px 10px 60px;
  background: #F8F4E9; }
  .content4 .productSection .title {
    text-align: center;
    font-size: 24px;
    color: #584206; }
  .content4 .productSection .typeList {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .content4 .productSection .typeList li {
      width: 50%;
      box-sizing: border-box; }
      .content4 .productSection .typeList li img {
        width: 100%; }
    .content4 .productSection .typeList a {
      display: inline-block;
      text-decoration: none; }
      .content4 .productSection .typeList a:hover {
        opacity: 0.7; }
    .content4 .productSection .typeList .text {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #F17070;
      font-size: 16px;
      font-weight: bold;
      color: #fff; }
      .content4 .productSection .typeList .text.type2 {
        background: #B77C17; }
      .content4 .productSection .typeList .text.type3 {
        background: #BA4697; }
      .content4 .productSection .typeList .text.type4 {
        background: #5246BA; }
  .content4 .productSection .note {
    margin-top: 15px;
    padding: 0 10px;
    text-align: center;
    font-size: 15px;
    color: #333; }
  .content4 .productSection .polish {
    margin: 40px 0 0;
    padding: 28px 30px;
    background: #fff; }
    .content4 .productSection .polish .image {
      width: 100%; }
      .content4 .productSection .polish .image img {
        width: 100%; }
    .content4 .productSection .polish .text1 {
      margin-top: 10px;
      font-size: 23px;
      font-weight: bold;
      color: #5B8D26; }
    .content4 .productSection .polish .text2 {
      margin-top: 10px;
      line-height: 1.8;
      font-size: 15px; }

.content4 .reasonSection {
  padding: 60px 10px; }
  .content4 .reasonSection .reasonsList {
    margin-top: 60px;
    color: #333; }
    .content4 .reasonSection .reasonsList li {
      justify-content: space-between; }
      .content4 .reasonSection .reasonsList li:nth-child(n+2) {
        margin-top: 40px; }
      .content4 .reasonSection .reasonsList li:nth-child(odd) {
        flex-direction: row; }
      .content4 .reasonSection .reasonsList li:nth-child(even) {
        flex-direction: row-reverse; }
      .content4 .reasonSection .reasonsList li .image {
        width: 100%; }
        .content4 .reasonSection .reasonsList li .image img {
          width: 100%; }
      .content4 .reasonSection .reasonsList li .title {
        margin-top: 10px;
        padding-bottom: 8px;
        line-height: 1.2;
        font-size: 28px;
        font-weight: bold; }
      .content4 .reasonSection .reasonsList li .fz32 {
        display: inline-block;
        margin-top: 5px;
        font-size: 26px; }
      .content4 .reasonSection .reasonsList li .fz26 {
        font-size: 22px; }
      .content4 .reasonSection .reasonsList li .fz20 {
        font-size: 16px; }
      .content4 .reasonSection .reasonsList li .bold {
        margin-top: 25px;
        font-size: 14px;
        font-weight: bold; }
      .content4 .reasonSection .reasonsList li .text {
        margin-top: 15px;
        line-height: 1.6;
        font-size: 14px; }
      .content4 .reasonSection .reasonsList li .button {
        margin-top: 20px;
        text-align: center; }
        .content4 .reasonSection .reasonsList li .button a {
          display: inline-block;
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          text-decoration: none;
          border-radius: 25px;
          background: #5B8D26;
          font-size: 15px;
          font-weight: bold;
          color: #fff; }
          .content4 .reasonSection .reasonsList li .button a:hover {
            opacity: 0.7; }

.content4 .compareSection {
  padding: 50px 10px 40px;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/content4/compare.png") no-repeat center;
  background-size: cover; }
  .content4 .compareSection .compareInner {
    margin: auto;
    padding: 30px 15px 30px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7); }
    .content4 .compareSection .compareInner .text1 {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #5B8D26; }
    .content4 .compareSection .compareInner .text2 {
      margin-top: 10px;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #333; }
    .content4 .compareSection .compareInner .text3 {
      margin-top: 30px;
      font-size: 12px; }
    .content4 .compareSection .compareInner .text4 {
      margin-top: 30px;
      padding: 0 20px;
      font-size: 12px; }
    .content4 .compareSection .compareInner .text5 {
      margin-top: 35px;
      padding: 0 20px;
      font-size: 12px; }

.content4 .voiceSection {
  text-align: center;
  padding: 50px 10px 40px; }
  .content4 .voiceSection .voiceList li {
    margin-top: 45px; }
  .content4 .voiceSection .voiceList .image {
    position: relative;
    height: 95vw;
    overflow: hidden; }
    .content4 .voiceSection .voiceList .image img {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto; }
  .content4 .voiceSection .voiceList .textWrap {
    padding: 10px;
    text-align: left; }
  .content4 .voiceSection .voiceList .name {
    font-size: 24px;
    font-weight: bold;
    color: #333; }
    .content4 .voiceSection .voiceList .name a {
      display: block;
      text-decoration: none;
      font-family: 'メイリオ','Mairyo',sans-serif;
      font-size: 18px;
      font-weight: normal;
      color: #707070; }
      .content4 .voiceSection .voiceList .name a:hover {
        text-decoration: underline; }
  .content4 .voiceSection .voiceList .icon {
    display: inline-block;
    box-sizing: border-box;
    min-width: 97px;
    margin-top: 18px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    background: #5B8D26;
    color: #fff; }
  .content4 .voiceSection .voiceList .text {
    margin: 10px 0 15px;
    line-height: 1.6;
    font-size: 15px;
    color: #333; }

.content4 .staffSection {
  padding: 40px 0 65px;
  background: #F8F4E9; }
  .content4 .staffSection .staffSectionTitle {
    font-size: 30px;
    text-align: center;
    color: #584206; }
    .content4 .staffSection .staffSectionTitle:before {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      width: 0;
      height: 34px;
      margin-right: 20px;
      border-right: 1px solid #584206;
      transform: rotate(-15deg); }
    .content4 .staffSection .staffSectionTitle:after {
      content: "";
      display: inline-block;
      position: relative;
      top: 5px;
      width: 0;
      height: 34px;
      margin-left: 20px;
      border-right: 1px solid #584206;
      transform: rotate(15deg); }
  .content4 .staffSection .staffList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #584206;
    margin: 0 -12px 0; }
    .content4 .staffSection .staffList li {
      width: 170px;
      margin: 34px 12px 0; }
    .content4 .staffSection .staffList .picture img {
      width: 100%; }
    .content4 .staffSection .staffList .department {
      margin-top: 15px;
      text-align: center;
      font-size: 10px; }
    .content4 .staffSection .staffList .name {
      margin-top: 5px;
      text-align: center;
      font-size: 16px; }

.content4 .voiceSection .heading {
  display: flex;
  align-items: center;
  margin: 70px auto 50px;
  font-size: 18px;
  font-weight: bold; }

.content4 .voiceSection .heading:before,
.content4 .voiceSection .heading:after {
  content: "";
  flex-grow: 1;
  border-top: 1px solid; }

.content4 .voiceSection .heading:before {
  margin-right: 1rem; }

.content4 .voiceSection .heading:after {
  margin-left: 1rem; }

.content4 .voiceSection .telBox {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: auto;
  border: 1px solid #E2E2E2; }
  .content4 .voiceSection .telBox p {
    text-align: center; }
  .content4 .voiceSection .telBox .textTop {
    font-size: 16px;
    font-weight: bold;
    color: #333; }
  .content4 .voiceSection .telBox .tel {
    margin-top: 10px;
    font-size: 26px;
    font-family: 'Century Gothic';
    font-weight: bold;
    color: #333; }
    .content4 .voiceSection .telBox .tel .num {
      margin-left: 20px;
      font-size: 30px; }
  .content4 .voiceSection .telBox .time {
    font-size: 17px;
    font-weight: bold;
    color: #333; }

.content4 .voiceSection .formButton {
  margin-top: 60px;
  text-align: center; }
  .content4 .voiceSection .formButton a {
    display: inline-block;
    width: 360px;
    height: 50px;
    line-height: 50px;
    border-radius: 25px;
    text-align: center;
    text-decoration: none;
    background: #5B8D26;
    font-size: 15px;
    font-weight: bold;
    color: #fff; }
    .content4 .voiceSection .formButton a:hover {
      opacity: 0.7; }

/*****************************************************
*****************************************************
all
*****************************************************
*****************************************************/
.breadcrumb span {
  padding: 0 10px;
  color: #666;
  font-size: 11px;
  vertical-align: middle; }

.inline-ul {
  display: flex;
  justify-content: center; }

/* variable
----------------------------------------------- */
/* hedden
----------------------------------------------- */
.sp-hidden {
  display: none !important; }

/* column
----------------------------------------------- */
.flex-wrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start; }

.flex-item {
  flex: 1 1 auto; }

/*****************************************************
*****************************************************
ショッピングインフォメーション
*****************************************************
*****************************************************/
/* wp-style single-style
----------------------------------------------- */
.archive-title {
  font-size: 32px;
  font-weight: 500;
  border-bottom: 1px solid #2E3739;
  margin: 0;
  padding: 0 0 15px;
  line-height: 1.2; }

.wp-style .entry-content {
  padding-top: 100px;
  margin-top: -100px; }
  .wp-style .entry-content h2 {
    color: #333;
    background: #FFFEEE;
    border: 1px solid #D8AF1C;
    padding: 15px 18px;
    margin: 60px 0 40px;
    border-radius: 3px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2; }
  .wp-style .entry-content h3 {
    border: none;
    border-bottom: 1px solid #D8AF1C;
    padding: 0 5px 10px;
    margin: 50px 0 40px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2; }
  .wp-style .entry-content h4 {
    border: none;
    margin: 40px 0;
    padding: 5px 0;
    border-left: 3px solid #D8AF1C;
    padding-left: 15px;
    font-size: 18px;
    line-height: 1.2; }
  .wp-style .entry-content h5, .wp-style .entry-content h6 {
    font-size: 17px; }
  .wp-style .entry-content p {
    font-size: 16px;
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 1.2em 0; }
  .wp-style .entry-content li {
    list-style-type: disc; }
  .wp-style .entry-content ul, .wp-style .entry-content ol {
    padding-left: 1.6em;
    margin: 1em 0;
    line-height: 1.6;
    text-align: left; }
    .wp-style .entry-content ul ul, .wp-style .entry-content ul ol, .wp-style .entry-content ol ul, .wp-style .entry-content ol ol {
      margin-top: .5em;
      margin-bottom: .5em; }
  .wp-style .entry-content ul > li > ul {
    list-style-type: circle; }
  .wp-style .entry-content dl {
    padding-left: .5em;
    line-height: 1.6;
    text-align: left; }
    .wp-style .entry-content dl dt {
      margin-bottom: .5em;
      font-weight: bold; }
    .wp-style .entry-content dl dd {
      margin-bottom: .25em; }
  .wp-style .entry-content table {
    width: 100%;
    font-size: 95%;
    margin-bottom: 3em; }
  .wp-style .entry-content table caption {
    margin: 0 0 7px;
    font-size: 90%;
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 1px; }
  .wp-style .entry-content td,
  .wp-style .entry-content th {
    padding: 1.2em .8em;
    border: 1px solid #d8d8d8; }
  .wp-style .entry-content td input {
    margin: 3px 0; }
  .wp-style .entry-content th {
    background: rgba(0, 0, 0, 0.05);
    text-align: left; }
  .wp-style .entry-content blockquote {
    padding: 3% 4%;
    margin: 2em 0;
    position: relative;
    border: none;
    border-radius: 5px;
    font-size: .85em;
    background: #F7F7F7;
    color: #666; }
    .wp-style .entry-content blockquote::before, .wp-style .entry-content blockquote::after {
      color: #bbb;
      position: absolute;
      display: block;
      line-height: 1;
      font-size: 45px; }
    .wp-style .entry-content blockquote::before {
      content: "“";
      left: 15px;
      top: 15px; }
    .wp-style .entry-content blockquote::after {
      content: "”";
      right: 13px;
      bottom: -15px; }
    .wp-style .entry-content blockquote img {
      width: auto;
      max-width: 100%; }
  .wp-style .entry-content strong {
    font-size: 110%;
    display: inline-block;
    padding: 0 0.3rem;
    margin: 0;
    position: relative; }
    .wp-style .entry-content strong::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2px;
      height: 3px;
      background: #D8AF1C; }

#page-shopping-guide {
  font-size: 16px;
  line-height: 2; }
  #page-shopping-guide .flex-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    padding: 15px;
    box-sizing: border-box; }
  #page-shopping-guide .flex-item {
    flex: 1 1 100%; }
  #page-shopping-guide .main-contents {
    order: 2; }
  #page-shopping-guide .anchor-wrap {
    order: 1;
    margin-bottom: 40px; }
  #page-shopping-guide .anchor-nav {
    position: sticky;
    top: 40px; }
    #page-shopping-guide .anchor-nav dt {
      background: #2E3739;
      color: #FFF;
      padding: 6px 10px;
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 3px; }
    #page-shopping-guide .anchor-nav dd a {
      display: block;
      border-bottom: 1px solid #DDD;
      color: #333;
      text-decoration: none;
      padding: 6px 0;
      font-size: 14px;
      transition: all 0.3s ease; }
      #page-shopping-guide .anchor-nav dd a:hover {
        background-color: #f5f8fa;
        transition: all 0.3s ease;
        color: #333; }

/*****************************************************
*****************************************************
安心安全の取り組み
*****************************************************
*****************************************************/
.wrapper {
  position: relative; }

.mainview-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/bg-page-safety.jpg") #eee no-repeat 50%;
  background-size: cover;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .mainview-wrap h1 {
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 1.5;
    color: #FFF;
    text-shadow: 0px 0px 6px #000; }
  .mainview-wrap span {
    display: block; }

/* safety-contents
----------------------------------------------- */
#page-safety {
  padding-top: 72vh; }
  #page-safety .safety-contents {
    margin-top: 100px;
    padding: 0 15px; }
    #page-safety .safety-contents * {
      box-sizing: border-box; }
    #page-safety .safety-contents h2 {
      color: #333;
      margin: 40px 0 30px;
      font-size: 28px;
      font-weight: bold;
      line-height: 1.2; }
    #page-safety .safety-contents p {
      font-size: 16px;
      line-height: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 1em 0; }
  #page-safety .bd-sand-side {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin: 0 auto 30px; }
    #page-safety .bd-sand-side:before, #page-safety .bd-sand-side:after {
      content: "";
      flex: 0 0 60px;
      height: 1px;
      background: #333;
      display: block; }
    #page-safety .bd-sand-side:before {
      margin-right: 20px; }
    #page-safety .bd-sand-side:after {
      margin-left: 20px; }
  #page-safety .bd-content {
    box-sizing: border-box;
    border: 2px solid #E2E2E2;
    padding: 20px 0 10px;
    margin: 40px auto; }
  #page-safety .process-type {
    margin-bottom: 40px;
    flex-flow: row wrap; }
    #page-safety .process-type li {
      padding: 2px;
      font-weight: bold;
      flex: 0 0 100%; }
    #page-safety .process-type .type01 {
      color: #BA4697; }
    #page-safety .process-type .type02 {
      color: #F17070; }
    #page-safety .process-type .type03 {
      color: #378DC7; }
  #page-safety .flex-wrap {
    justify-content: center; }
  #page-safety .flex-item {
    flex: 0 0 calc(100%/2 - 7px);
    padding: 0 7px;
    margin-bottom: 30px; }
    #page-safety .flex-item * {
      text-align: left; }
    #page-safety .flex-item .num {
      display: block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: #FFF;
      background: #666;
      text-align: center;
      font-family: 'arial' sans-serif;
      font-weight: bold;
      font-size: 11px;
      border-radius: 100px;
      margin-right: 8px; }
      #page-safety .flex-item .num.type01 {
        background: #BA4697; }
      #page-safety .flex-item .num.type02 {
        background: #F17070; }
      #page-safety .flex-item .num.type03 {
        background: #378DC7; }
    #page-safety .flex-item .title-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 1.2;
      font-size: 15px;
      font-weight: bold;
      margin: 15px 0 10px; }
    #page-safety .flex-item .txt-wrap {
      line-height: 1.6;
      font-size: 12px;
      margin: 0; }
  #page-safety #polished-rice {
    margin-top: 50px; }

/*****************************************************
*****************************************************
人気お米ランキング
*****************************************************
*****************************************************/
#page-ranking .textTitle {
  font-size: 34px;
  color: #2E3739; }

#page-ranking .textSubTitle {
  display: inline-block;
  position: relative;
  height: 28px;
  line-height: 28px;
  padding: 0 25px;
  margin-top: 15px;
  background: #D8AF1C;
  font-size: 14px;
  color: #fff; }
  #page-ranking .textSubTitle::before, #page-ranking .textSubTitle::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    margin: auto; }
  #page-ranking .textSubTitle::before {
    left: -14px;
    border-right: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }
  #page-ranking .textSubTitle::after {
    right: -14px;
    border-left: 14px solid #D8AF1C;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent; }

#page-ranking .main-contents,
#page-ranking .main-contents * {
  text-align: center; }

#page-ranking .entry-content {
  padding-left: 5px;
  padding-right: 5px; }
  #page-ranking .entry-content h2 {
    padding: 12px;
    font-size: 21px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px; }

#page-ranking .flex-wrap.ranking {
  margin: 0;
  padding: 0 0 50px;
  justify-content: center; }
  #page-ranking .flex-wrap.ranking .flex-item {
    flex: 0 1 calc(100%/2 - 10px);
    padding: 5px;
    margin-bottom: 10px;
    list-style: none; }
    #page-ranking .flex-wrap.ranking .flex-item:first-child {
      flex: 0 1 100%; }
    #page-ranking .flex-wrap.ranking .flex-item em {
      display: block;
      text-indent: -9999em; }
    #page-ranking .flex-wrap.ranking .flex-item p {
      margin: 8px 0;
      font-weight: bold;
      line-height: 1.5; }
    #page-ranking .flex-wrap.ranking .flex-item a {
      text-decoration: none;
      color: #333; }
      #page-ranking .flex-wrap.ranking .flex-item a:hover {
        text-decoration: underline; }
    #page-ranking .flex-wrap.ranking .flex-item .img-wrap {
      margin-bottom: 15px; }
      #page-ranking .flex-wrap.ranking .flex-item .img-wrap img {
        width: auto;
        max-width: 253px; }
    #page-ranking .flex-wrap.ranking .flex-item .title-wrap {
      font-size: 15px; }
    #page-ranking .flex-wrap.ranking .flex-item .txt-wrap a {
      color: #E34545;
      font-size: 15px; }
      #page-ranking .flex-wrap.ranking .flex-item .txt-wrap a .bx-span {
        padding: 5px;
        margin-right: 6px;
        display: inline-block;
        color: #FFF;
        background: #E34545;
        font-size: 10px;
        line-height: 1;
        vertical-align: middle; }
      #page-ranking .flex-wrap.ranking .flex-item .txt-wrap a .tax {
        font-size: 10px;
        line-height: 1; }
  #page-ranking .flex-wrap.ranking .rank1 em {
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon1.png) no-repeat center;
    width: 40px;
    height: 40px;
    margin: auto; }
  #page-ranking .flex-wrap.ranking .rank2 em {
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon2.png) no-repeat center;
    width: 40px;
    height: 40px;
    margin: auto; }
  #page-ranking .flex-wrap.ranking .rank3 em {
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon3.png) no-repeat center;
    width: 40px;
    height: 40px;
    margin: auto; }
  #page-ranking .flex-wrap.ranking .rank4 em {
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon4.png) no-repeat center;
    width: 40px;
    height: 40px;
    margin: auto; }
  #page-ranking .flex-wrap.ranking .rank5 em {
    background: url(https://gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rankIcon5.png) no-repeat center;
    width: 40px;
    height: 40px;
    margin: auto; }

/*****************************************************
*****************************************************
TOPの基礎知識（WP）
*****************************************************
*****************************************************/
.howto {
  text-align: center; }

.inner-howto {
  margin-top: 40px; }

#iframe-howto {
  height: 550px; }

.detailButton {
  padding: 0 15px;
  text-align: center; }
  .detailButton a {
    display: block;
    height: 42px;
    margin-top: 20px;
    line-height: 42px;
    text-decoration: none;
    border-radius: 30px;
    background: #5B8D26;
    font-size: 15px;
    font-weight: bold;
    color: #fff; }
