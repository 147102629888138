
.titleWrap{
	.titleText{
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		box-sizing: border-box;
		width: 65px;
		height: 65px;
		margin: -32px auto 0;
		border-radius: 50px;
		text-decoration: none;
		text-align: center;
		background: #5B8D26;
		color: #FFFEEE;

		&:hover{
			opacity: 0.7;
		}
		span{
			line-height: 0.8;
			font-size: 14px;
		}
	}
}
.titleText2{
	margin-top: 15px;
	padding: 0 10px;
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	color: #5B8D26;
}
.text{
	margin-top: 20px;
	padding: 0 10px;
	text-align: center;
	font-size: 12px;
	color: #333;
}
.fastSection{
	margin-top: 20px;
}
.inexpensiveSection{
	margin-top: 30px;
	.text{
		margin: 20px;
	}

}
.safeSection{
	margin-top: 30px;
	.text{
		margin: 20px;
	}
}

.imageList{
	margin: 40px auto;
	li{
		border: 2px solid #E2E2E2;
		margin: 10px;

		.textWrap{
			line-height: 1.8;
			padding: 15px 15px;
			text-align: left;
			p{
				margin-top: 10px;
				font-size: 12px;
			}
		}
		.imageListTitle{
			font-size: 18px;
			font-weight: bold;
			span{
				line-height: 1.2;
				vertical-align: middle;
			}
			.listTitle{
				display: block;
				margin-top: 10px;
			}
			.icon{
				display: inline-block;
				margin-right: 15px;
				padding: 5px 13px;
				border-radius: 5px;
				background: #B77C17;
				font-size: 13px;
				color: #fff; 
				span{
					position: relative;
					top: -1px;
					margin-left: 5px;
					font-size: 20px;
				}
			}	
		}
	}
}



.inner{
	width: 100%;
	margin: auto;
}
.title{
	margin: 15px 10px;
	text-align: center;
	font-size: 18px;
	span{
		font-weight: bold;
	}
}
.fukuimaiList{
	// display: flex;
	// justify-content: space-between;
	li{
		// flex-shrink: 0;
		box-sizing: border-box;
		// width: 285px;
		margin: 10px;
		padding: 30px 20px;
		border: 3px solid rgba(62,38,28,0.2);
		border-radius: 4px;
		.name{
			margin: 0;
			font-family: "游明朝",serif;
			font-size: 27px;
			color: #3E261C;
			span{
				display: inline-block;
				position: relative;
				width: 68px;
				height: 22px;
				top: -5px;
				border-radius: 3px;
				text-align: center;
				line-height: 22px;
				margin-right: 5px;
				font-size: 11px;
				font-weight: bold;
				background: #3E261C;
				color: #fff;

			}
		}
		p{
			margin-top: 20px;
			font-size: 12px;
			color: #333;
		}
	}
}
.ingredientText{
	margin: 40px 10px 30px;
	text-align: center;
	font-size: 16;

}
.fukidasi{
	margin: 45px 0 23px;
	text-align: center;
	font-size: 16px;
	color: #B77C17;
	&:before{
		content: "";
		display: inline-block;
		position: relative;
		top: 4px;
		width: 0;
		height: 20px;
		margin-right: 10px;
		border-right: 1px solid #B77C17;
		transform: rotate(-15deg);
	}
	&:after{
		content: "";
		display: inline-block;
		position: relative;
		top: 4px;
		width: 0;
		height: 20px;
		margin-left: 10px;
		border-right: 1px solid #B77C17;
		transform: rotate(15deg);
	}

}
.ajiText{
	padding: 0 10px;
	text-align: center;
	font-size: 15px;
	color: #333;
	span{
		font-weight: bold;
		color: #CC0000;
	}

}
.ricePicture{
	display: flex;
	justify-content: space-between;	
	margin-top: 25px;
	p{
		width: 440px;
		img{
			width: 100%;
		}
	}
}

.heading_border{
	display: flex;
	align-items: center;
	width: 300px;
	margin: 70px auto 50px;
	font-size: 18px;
	font-weight: bold;
}
.heading_border:before,
.heading_border:after {
	content: "";
	flex-grow: 1;
	border-top: 1px solid;
}
.heading_border:before {
	margin-right: 1rem;
}
.heading_border:after {
	margin-left: 1rem;
}
.riceField{
	margin-top: 30px;
	padding: 50px 20px;
	background: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/category/ricefield.png') no-repeat center;

	.riceFieldInner{
		padding: 20px 10px;
		background: rgba(255,255,255,0.85);
	}
	.text1{
		text-align: center;
		font-family: "游明朝",serif;
		font-size: 26px;
		color: #333;
	}
	.text2{
		margin-top: 12px;
		line-height: 1.9;
		text-align: center;
		font-size: 12px;
	}
}

.riceGraph{

	text-align: center;
	img{
		width: 960px;
	}
}