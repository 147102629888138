/* CSS Document */


.currentName{
    text-align:left;
    font-size:18px; 
    padding:0 10px;
    margin-bottom:20px;
}

.no-resultWrap{
    padding: 32px 0 64px;   
}

.resultPath:nth-child(n + 2)::before{
    content:"、";
}

.catImage{
    // margin-bottom:20px; 
}

#catNarrowdown h3 span{
    padding-right:30px;
    background:url("/smartphone/images/template/5/icon-close.png") no-repeat right center;  
    background-size:26px 26px;
}

#catNarrowdown.subCatOpen h3 span{
    background-image:url("/smartphone/images/template/5/icon-open.png");
}

#catNarrowdown{
    margin-bottom:15px; 
}

#catNarrowdown a{
    display:block;
    background:#5E5E5E;
    padding:15px 0;
    color:#fff;
    font-weight:normal;
    font-size:14px;
    box-sizing:border-box;
}

#child_category_list a{
    padding:10px;
    border-color:#999;
    font-size:12px;
}   

.itemHead{
    padding:10px 10px 0;    
    border-top:3px solid #ccc;
}

.resultCount{
    text-align:left;
    padding:0 10px 8px; 
    font-size:12px;
}

.sortOrder{
    text-align:center;
    letter-spacing: -0.4em;
}

.sortOrder li{
    display:inline-block;   
    padding:2px 0;
    box-sizing:border-box;
    width:25%;
    letter-spacing:normal;
    font-size:12px;
    border-right:1px solid #ccc;
}

.sortOrder li a{
    display:block;  
}

.sortOrder li:last-child{
    border-right:none;  
}

.searchHead{
    display:table;  
    padding:10px;
    width:100%;
    box-sizing:border-box;
    border-bottom:1px solid #ccc;
}

.viewCount,.viewSelect{
    display:table-cell;
    font-size:13px; 
    width:50%;
    text-align:center;
}

select[name="item_list_limit"]{
    width:80px; 
    padding:4px 6px;
}

/* リスト形式 */

#thumb_images{
    padding-bottom:90px;    
}

.listBox #list_item li{
    display:table;
    width:100%;
    border-bottom:1px dotted #ccc;
    padding-bottom:10px;
    margin:0 0 10px 0;
}

.listBox #list_item .floatL,
.listBox #list_item .itemDetail{
    display:table-cell; 
    vertical-align:top;
    padding-left:5px;
}
#list_item .itemDetail{
    .price{
    	font-size: 13px;
    	color: #E22828;
    	em{
    		font-size: 18px;
    		font-weight: bold;
    	}
    }
}

.listBox #list_item .floatL{
    width:80px; 
}

.reviewCount{
    display:inline; 
    color:#999;
}

.reviewRateStar{
    width:15px;
    height:15px;
    background-size:15px 15px;
    margin:0 0.5px; 
}

.listGrid .reviewCount{
    display:none;   
}   

.matomegai{
	display: inline-block;
	margin-top: 8px;
}

.matomegai a{	
	display: inline-block;
	border: 1px solid #54B3DE;
	border-radius: 4px;
	color: #54B3DE;
	font-size: 10px;
	padding: 4px 6px;
}

.listBox .matomegai{
	margin-left: 5px;
}

/* 詳細検索 */
.searchForm{
    border-top:1px solid #dcdcdc;
    background:#fafafa; 
    box-sizing:border-box;
    text-align:left;
    margin-bottom:-60px;
}

.searchTitle{
    font-size:16px;
    padding:10px;
    border-bottom:1px dotted #dcdcdc;
}

.searchFormWrap{
    padding:10px;   
}

.searchBtnWrap{
    text-align:center;  
    padding-bottom:20px;
}

.searchBtn{
    background:#54b3de;
    text-align:center;
    color:#fff !important;
    margin:15px auto;
}

.searchItem{
    width:100%;
    display:table;  
}

.searchItem dt,
.searchItem dd{
    display:table-cell; 
    padding:8px 0;
    font-size:14px;
}

.searchItem dt{
    width:30%;  
}

.searchItem select{
    background-color:#fff;  
}

input[type="text"].inputPrice{
    width:5em;
    margin-right:4px;   
}