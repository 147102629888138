/*************** reset */

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}

body {
	line-height:1;
}

img{max-width:100%;}

*{
	webkit-appearance:none; 
	-webkit-appearance: none;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section { 
	display:block;
}

nav ul {
	list-style:none;
}

blockquote, q {
	quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}

div,p{
	word-break: break-all;	
}

a {
	margin:0;
	padding:0;
	font-size:100%;
	vertical-align:baseline;
	background:transparent;
}


table {
	border-collapse:collapse;
	border-spacing:0;
}

input, select,textarea{
	vertical-align:middle;
	border:none;
	font-size:inherit;
}


input[type="text"],
input[type="number"],
input[type="search"],
select{
	padding:10px;
	border:1px solid #808080;   
	box-sizing:border-box;
	width:100%;
}

select{
	background:url("/smartphone/images/template/5/icon-select.png") no-repeat 92% center;
	background-size:20px 20px;
	padding-right:36px; 
}

.clear:after{
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

/***************************************

	  layout

***************************************/

body{
	font-family: "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Meiryo","メイリオ","ＭＳ Ｐゴシック","MS PGothic",sans-serif;
	background: #ffffff;
	text-align:center;  
	line-height:1.5;
	color:#333;
	position:relative;
	overflow-x:hidden;
	right:0;
	font-size:14px;
}
html{
	body{
		margin-top: 88px;
	}
}

img{
	max-width:100%; 
	vertical-align:bottom;
}

img[width="1"],img[width="0"]{
	display:none!important; 
}


/***************************************

	  head

***************************************/
.topText{
	padding: 2px;
	text-align: center;
	background: #5B8D26;
	font-size: 12px;
	font-weight: bold;
	color: #fff;
	span{
		font-size: 10px;
		font-weight: normal;
	}
}
.navBar{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	box-sizing: border-box;
	z-index:1000;
	background:#fff;
	border-bottom:1px solid #E2E2E2;
	ul{
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		min-height: 64px;
		li{
			&:first-child{
				flex-basis: 52%;
				flex-grow: 1;
				text-align: left;
				img{
					width: 198px;
				}
				a{
					display: block;
					width: 100%;
					box-sizing: border-box;
					padding: 4px 10px 0 10px;
				}
			}
			&:nth-child(n+2){
				background: #fff;
				box-sizing:border-box;
				font-size:10px;
				flex-basis: 16%;
				padding: 0 0 6px;
			}
			&.navOder {
				display: flex;
				border-left: 1px solid #E2E2E2;
				color: #E34545;
				i{
					font-size: 24px;
					display: block;
					margin-bottom: 6px;
				}
				a{
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: center;
					// min-width: 64px;
					text-decoration: none;
					font-weight: bold;
					color: #E34545;
				}
			}
			&.navCart{
				display: flex;
				border-right: 1px solid #E2E2E2;
				border-left: 1px solid #E2E2E2;
				color: #E34545;
				img{
					width: 30px;
					display: block;
				}
				a{
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: center;
					width: 100%;
					// min-width: 64px;
					text-decoration: none;
					font-weight: bold;
					color: #E34545;
					img{
						margin-bottom: 7px;
					}
				}
			}
			&.navMenu {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				// min-width: 64px;	
				font-weight: bold;
				div{
					display: block;
					position: relative;
					width: 100%;
					height: 18px;
					margin-bottom: 10px;
					span{
						position: absolute;
						display: block;
						width: 20px;
						height: 2px;
						right: 0;
						left: 0;
						margin: auto;
						background: #2E3739;
					}
					span:nth-child(1){top: 0;}
					span:nth-child(2){top: 0; bottom: 0;}
					span:nth-child(3){bottom: 0;}
				}
			}
		}
	}
}   
.open{
	.navBar{
		ul{
			li{
				&.navMenu {
					div{
						span{
							position: absolute;
							right: 0;
							left: 0;
							margin: auto;
							background: #2E3739;
						}
						span:nth-child(1){top: 0; bottom: 0; transform: rotate(45deg);}
						span:nth-child(2){transform: rotate(45deg);}
						span:nth-child(3){top: 0; bottom: 0; transform: rotate(135deg);}
					}
				}
			}
		}
	}
}


.navCartIcon{
	position: absolute;
	right: 12px;
	top: 8px;
	width: 17px;
	height: 17px;
	font-size: 11px;
	border-radius: 9px;
	background: #c7004c;
	box-shadow: 0px 1px 2px #ccc;
	line-height: 17px;
	color: #fff; 
	font-family: Arial, Helvetica, "sans-serif";
}

#sideMenu{
	position:fixed;
	width: 90%;
	height: 100%;
	top: 0;
	right:-90%;
	padding: 121px 25px 25px;
	box-sizing: border-box;
	background:#fff;
	overflow:scroll;
	z-index: 100;
}
.open .sideMenuBg{
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: #333;
	opacity: 0.7;
	z-index: 90;
}

.heading{
	display: flex;
	align-items: center;
	margin: 30px auto 20px;
	font-size: 16px;
	font-weight: bold;
}
.heading:before,
.heading:after {
	content: "";
	flex-grow: 1;
	border-top: 1px solid;
}
.heading:before {
	margin-right: 1rem;
}
.heading:after {
	margin-left: 1rem;
}

.searchArea{
	background:#333;
	padding:15px 10px;
	text-align:left;    
}

input[name="brand_search_form"]{
	display:inline-block;
	width:80%;
	padding:10px;
	box-sizing:border-box;
	border:none;
}

input[name="brand_search_button"]{
	border-radius:6px;  
	box-sizing:border-box;
	background:url("/smartphone/images/template/5/icon-search.png") no-repeat center center;
	background-size:26px 26px;
	text-indent:-9999em;
	width:40px;
	height:30px;
}

.sideNav{
	margin:15px 0 100px;
	text-align:left;
	font-size:15px;
}

.sideNav,.sideNav a{
	color:#333;
}

.sideNav li a{
	padding:10px;
	display:block;  
}
.sideNav-login a{
	display: block;
	height: 44px;
	line-height: 44px;
	text-align: center;
	text-decoration: none;
	border: 1px solid #95989A;
	border-radius: 8px;
	background: #F7F7F7;
	font-size: 15px;
	font-weight: bold;
	color: #2E3739;
}
.topButton{
	display: flex;
	margin: 30px -5px 30px;
	p{
		width: 50%;
	}
	a{
		display: block;
		height: 44px;
		margin: 0 5px;
		line-height: 44px;
		text-align: center;
		text-decoration: none;
		border: 1px solid #95989A;
		border-radius: 8px;
		background: #F7F7F7;
		font-size: 15px;
		font-weight: bold;
		color: #2E3739;
	}
} 

.heading2{
	margin: 20px 0;
	font-size: 15px;
	font-weight: bold;
	color: #2E3739;
}
.heading3{
	height: 30px;
	margin: 20px 0 0;
	line-height: 30px;
	text-align: center;
	background: #2E3739;
	font-size: 15px;
	font-weight: bold;
	color: #fff;
}

//種類 から選ぶ
.sideCategory2{
	border-bottom: 1px solid #E2E2E2;
	padding-bottom: 10px;
	&> ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		&> li{
			display: inline-block;
			width: 50%;
			margin: 0 -8px;
			&> a{
					display: flex;
					align-items: center;
					padding: 5px 8px;
					text-decoration: none;
					border: 1px solid transparent;
					font-size: 15px;
					color: #333;
				img{
					max-width: 60px;
					max-height: 68px;
					margin-right: 12px;
				}
				&.M_currentAnchor{
					border: 1px solid #D8AF1C;
					background: #FFFEEE;
					font-weight: normal;
				}
			}
		} 
	} 
}
.sideCategory3{
	@extend .sideCategory2;
	&> ul{
		&> li{
			&> a{
				padding: 5px 8px;
			}
		}
	} 
}

// .sideNav-title{
// 	padding: 10px;
// }

// .inner-sideNav{
// 	margin-bottom: 8px;	
// }

// .inner-sideNav li a{
// 	padding-left: 25px;
// }

// .categorySwitch{
// 	padding:10px;
// 	display:block;
// 	background:url("/smartphone/images/template/5/icon-close.png") no-repeat 90% center;
// 	background-size:26px 26px;
// }

// .categorySwitch.open{
// 	background-image:url("/smartphone/images/template/5/icon-open.png");    
// }

// .sideCategory{
// 	display:none;   
// }

#category_list2 {
	margin-top:5px;
}   

#category_list2 li a{
	font-size:13px;
}

.bottomButton{
	display: flex;
	flex-wrap: wrap;
	margin: 20px -5px 30px;
	p{
		width: 50%;
	}
	a{
		display: block;
		height: 44px;
		margin: 5px 5px;
		line-height: 44px;
		text-align: center;
		text-decoration: none;
		border: 1px solid #95989A;
		border-radius: 8px;
		background: #F7F7F7;
		font-size: 15px;
		font-weight: bold;
		color: #2E3739;
	}
}
.list{
	li{
		a{
			display: block;
			padding: 10px;
			text-align: left;
			text-decoration: none;
			border-bottom: 1px solid #E2E2E2;
			font-size: 13px;
			font-weight: bold;
			color: #2E3739;
		}
		&.child a{
			padding-left: 25px;
		}
	}
}

/***************************************

	  common

***************************************/

/* スライダー下の余白 */
#mainContents .bx-wrapper{
	margin-bottom: 35px;
}

#list_item,
.item,
.rankingList{
	text-align:left;
	margin:20px auto;   
	letter-spacing:-0.4em; 
}

#list_item li,
.item li,
.rankingList li{
	display:inline-block;
	vertical-align:top; 
	width:31%;
	margin-left:2%;
	font-size:12px;
	margin-bottom:15px;
	text-align:left;
	letter-spacing:normal;
}

#list_item li:nth-child(3n),
.item li:nth-child(3n),
.rankingList li:nth-child(3n){
	margin-right:1%;
}

#list_item .itemImgWrap,
.item .itemImg,
.rankingList .itemImg{
	text-align:center;
	margin-bottom:5px;
	position:relative;
}

.rankingNum{
	background:#2490d0;
	color:#fff;	
	border-radius:50%;
	width:22px;
	height:22px;
	line-height:22px;
	display:inline-block;
	position:absolute;
	left:-3px;
	top:-3px;
}

.rankingPrice{
	font-weight:bold;
}

.item .reviewRateStar{
	width:15px;
	height:15px;
	background-size:15px 15px;
	margin:0 0.5px;	
}

.item .reviewCount{
	display:none;	
}	

.itemDetail{
	text-align:left;    
}

.item span{
	font-weight:bold;   
}

.btn{
	display:inline-block;
	padding:10px;
	// border-radius:6px;  
	width:80%;
	box-sizing:border-box;
}


.pager{
	margin-top: 20px;
	display: inline-block;
}

.pager li{
	display: inline-block;
}

.pager span{
	display: inline-block;
	color: #333333;
	text-decoration: none;
	margin-right: 10px;
	background: #e8e8e8;
	border-radius: 50%;
	display: inline-block;
	padding: 8px 15px;
	font-size: 14px;
}

.pager span.btn-next,
.pager span.btn-prev{
	border-radius: 6px;
}

.pager span.current{
	background-color: #333;
	color: #FFF;
	border-radius: 50%;
}

/***************************************

	  content

***************************************/

#main-content{
	margin:10px 0;  
}

.heading{
	font-size:16px;
}

/***************************************

	  top

***************************************/

.index-section{
	margin-bottom:30px; 
}

.recommendHeading,
.newHeading,
.rankingHeading{
	background:#222;    
	color:#fff;
	padding:6px 0;  
}


.itemListReview{
	margin:10px auto 40px;		
}

.itemListReview li{
	width:100%;
	margin:0;
	display:block;
	border-bottom:1px solid #ccc;
}

.itemListReview a{
	display:block;
	padding:10px;
	box-sizing:border-box;
}

.itemListReview .itemBox{
	display:table;
}

.itemListReview .itemImg,
.itemListReview .itemDetail{
	display:table-cell;
	vertical-align:top;
}

.itemListReview .itemDetail{
	padding-left:5px;	
}

.itemListReview .itemImg{
	width:80px;	
}

.itemImg img{
	height:auto;	
}

.reviewBox{
	margin-top:8px;
	font-size:12px;
	color:#666;	
	background:#eee;
	padding:10px;
	width:100%;
	box-sizing:border-box;
}

.reviewNullText{
	text-align:center;	
	padding:32px 0 48px;
}

.catlistHeading{
	text-align:left;
	padding:0 0 10px 10px;
}

.catMenu a{
	display:block;
	text-align:left;
	padding:10px;
	font-size:15px;
	border-top:1px dotted #666; 
}

.catMenu li:last-child a{
	border-bottom:1px dotted #666;  
}

/***************************************

	  category

***************************************/

#topicPath,
.searchPath{
	background:#eee;
	text-align:left;
	padding:10px;
	font-size:11px; 
	margin-bottom:10px;
	word-wrap: break-word; 
}

#topicPath,
#topicPath a,
.searchPath{
	color:#666; 
}

#topicPath li{
	display:inline; 
}

.moreBtn a{
	display:inline-block;
	padding:10px;
	width:80%;
	border-radius:6px;
	border:3px solid #ccc;  
}

/***************************************

	  利用案内/特定商取引/個人情報保護ポリシー

***************************************/


h3.catTit {
	text-align:left;
	font-size:17px;
	margin:20px 10px 25px;
}

.editContent {
	margin:0 10px 24px;
	text-align:left;
}

.editContent h4{
	font-size:14px;
	margin-bottom:20px;
}

#lawList th{
	font-size:14px;
}

#lawList td{
	font-size:13px;
	margin-bottom:30px; 
	padding:15px 0 30px;
}

.editArea {
	font-size:12px;
	text-align:left;
}

.editArea pre {
	margin:10px;
	white-space:pre-wrap;
}

/***************************************

	  商品レビュー

***************************************/

/* レビューの星 */
.reviewRate{
	display:inline-block;	
	vertical-align:middle;
	font-size:0;
	margin:3px 0 8px -1px;
}

.reviewRateStar{
	display:inline-block;
	width:21px;
	height:21px;
	text-indent:-9999em;
	cursor:pointer;
	background:no-repeat center center;
	background-size:21px 21px;
	margin:0 1px;
}

.starOn{
	background-image:url(/smartphone/images/star_on.png);	
}

.starOff{
	background-image:url(/smartphone/images/star_off.png);		
}

.starHalf{
	background-image:url(/smartphone/images/star_half.png);		
}


/* レビューを書く */
.reviewContent{
	text-align:left;
	margin:0 10px 24px;	
}

.reviewForm dl {
	margin-bottom: 10px;
}
.reviewForm dd {
	margin-bottom: 15px;
}
.reviewForm dt {
	margin-bottom:4px;
	font-weight:bold;
}
.reviewForm dl input {
	padding: 6px 0;
	width: 99%;
}
.reviewForm dl textarea {
	padding: 6px 0;
	width: 99%;
}

.reviewForm .reviewRateStar{
	width:43px;
	height:43px;
	background-size:43px 43px;	
}

.reviewContent .required {
	background: #C00;
	color: white;
	display: inline-block;
	font-size: 11px;
	margin-left: 8px;
	padding: 1px 3px;
	font-weight: normal;
}

.reviewItem{
	margin: 16px auto;
	width: 100%;
	box-sizing: border-box;
	padding:16px;
	background:#eee;
	box-sizing:border-box;
	display:table;
}

.reviewItemUnit{
	display:table-cell;
	vertical-align: middle;
	text-align:left;
}
.reviewItemName{
	padding-left:8px;
	font-size:13px;
}

.reviewItemImg{
	width:80px;	
}

.reviewItemImg img{
	border: solid 1px #ccc;
	box-sizing: border-box;
	max-width:100%;
	height:auto;
}

.errorTxt{
	color:#cc0000;
	margin-bottom:5px;
}

.captionTxt{
	color:#888;	
	text-align:right;
	font-size:12px;
}

.reviewText{
	border: 1px solid #808080;
	box-sizing: border-box;
	padding: 10px;
	width: 100%;
	height:120px;
}

.btnPost{
	background:#999;
	text-align:center;
	color:#fff !important;	
	width:100%;
	padding:15px 0;
	font-size:16px;
}

#review #back .btn{	
	width:auto;
	font-size:13px;
	padding:10px 30px;
	background:#eee;
}


/***************************************

	  お知らせ

***************************************/
.newsHeading {
	background: #222;
	color: #fff;
	padding: 6px 0;
}

.newsList{
	margin: 15px;
}

.newsList li{
	text-align: left;
	margin-bottom: 5px;
}

.newsDate,
.newsTitle{
	vertical-align: top;
	display: table-cell;
}

.newsDate {
	color:#999;
	padding: 2px 5px 2px 0px;
	white-space: nowrap;
	font-size: 10px;
}

.newsTitle{
	font-size: 12px;
}

.newsIndexLink{
	margin-bottom: 10px;
}

/* お知らせ詳細 */

.newsMain{
	padding: 15px;
	text-align: left;
}

.newsMain .newsMainInfo{
	font-size: 12px;
	color: #999;
	margin-bottom: 5px;
	text-align: right;
}

.newsMain .newsMainTitle{
	font-size: 14px;
	margin-bottom: 10px;
}

.newsMain .newsMainWrap{
	margin-bottom: 30px;
	border-top: dotted 1px #999;
	border-bottom: dotted 1px #999;
}

.newsMain .newsMainContent{
	font-size: 12px;
	padding: 20px 10px 50px;
}

.newsMain .newsMainPager{
	width: 100%;
	margin-bottom: 20px;
	overflow:hidden;
}

.newsMain .newsMainPager li{
	text-align: center;
}

.newsMain .newsMainPager a{
	display: inline-block;
	background: #e5e5e5;
	color: #333;	
	width: 25%;
	white-space: nowrap;
}

.newsMain .newsMainPager a.btnPrev{
	float: left;
}

.newsMain .newsMainPager a.btnNext{
	float: right;
}

.newsMain .newsIndexBtn a{
	background: #333;
	color: #fff;	
	width: 100%;
	text-align: center;
}

/* お知らせ一覧 */

.newsListAll{
	margin-bottom: 30px;
}

.newsListAll .newsListContent li{
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: dotted 1px #999;
}

.newsListAll .newsDate,
.newsListAll .newsTitle{
	display: block;
}

.newsListAll .newsDate{
	font-size: 12px;
}

.newsListAll .newsTitle{
	font-size: 14px;
}

.newsTitle{
	font-size: 12px;
}

.newsListPager{
	margin: 0 auto;
	font-size: 12px;
}

/***************************************

	  footer

***************************************/

#footer-content{
	background: #2E3739; 
	font-size:12px;
	padding: 30px 10px 10px;
}

.footerNav{
	margin-bottom: 20px;
	padding: 0 35px;
}

.footerNav-link{
	font-size:10px;
	display:inline-block; 
	padding: 4px;
}

.footerNav-link a{
	text-decoration: none;
	font-size: 11px;
	color:#fff; 
}

.btn-switch{
	background:#fff;    
	color:#666;
	width:60%;
}

.copyright{
	font-size:10px;
	color:#fff;
}


.footerCTA{
	border-top: 1px solid #eee;
	padding: 30px 0;
	background: #fff;
	font-weight: bold;
	p{
		text-align: center;
	}
	.text1{
		font-size: 13px;
	}
	.text2{
		font-size: 42px;
		font-family: Century Gothic,Arial,sans-serif;
	}
	.text3{
		font-size: 12px;
	}
	.text4{
		a{
			display: block;
			width: 70%;
			margin: 12px auto 0;
			padding: 10px;
			text-decoration: none;
			background: #2E3739;
			color: #fff;
		}
	}
}
.footerMiddle{
	padding: 20px 0;
	background: #FFFEEE;
	p{
		text-align: center;
		font-size: 13px;
		font-weight: bold;
	}
	.icon{
		margin-top: 10px;
	}
	a{
		margin-left: 10px;
		&:first-child{
			margin-left: 0;
		}
	}
}
.footerBottom{
	padding: 25px 0;
	background: #F7F7F7;
	.image{
		width: 250px;
		margin: auto;
	}
	.text{
		line-height: 1.6;
		text-align: center;
		margin-top: 15px;
		font-size: 12px;
		color: #333;
	}
}

.pageTop{
	position: fixed;
	right: 15px;
	bottom: 15px;
	text-align:right;
	a{
		display: inline-block;
		position: relative;
		border-radius: 3px;
		box-sizing: border-box;
		width: 35px;
		height: 35px;
		background: #eee;
		&:before{
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			width: 8px;
			height: 8px;
			margin: auto;
			border-top: 2px solid #333;
			border-left: 2px solid #333;
			transform: rotate(45deg);
		}
		&:after{
			content: "";
			display: inline-block;
			position: absolute;
			top: 12px;
			bottom: 0;
			right: 0;
			left: 0;
			width: 8px;
			height: 8px;
			margin: auto;
			border-top: 2px solid #333;
			border-left: 2px solid #333;
			transform: rotate(45deg);
		}
	}
}


@import"makeshopsp_top";
@import"makeshopsp_category";
@import"makeshopsp_detail";
@import"makeshop_fukui_sp";
@import"makeshopsp_individual";