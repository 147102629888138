/*****************************************************

	全ての要素をリセット
	各ブラウザの表示を統一するための設定です

*****************************************************/
// html {overflow-y: scroll;}
// body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {margin: 0;padding: 0;}
// address,caption,cite,code,dfn,em,strong,th,var {font-style: normal;}
// table { border-collapse: collapse;border-spacing: 0;}
// caption,th,p {text-align: left;}
// h1,h2,h3,h4,h5,h6 {font-size: 100%;text-align:left;}
// img,abbr,acronym,fieldset {border: 0;}
// li {list-style-type: none;}
// img{vertical-align:middle;}
// .line {font-size:1px;line-height:1px;}

// /*--- default */
// body {
// 	background: #fff;
// 	color: #333;
// 	font-size: 13px;
// 	font-family: "游ゴシック体", "Yu Gothic", "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
// 	font-weight: 500;
// 	text-align:center;
// }
// body td,body th{
// 	font-size:13px;
// }
// table{
// 	border-collapse:collapse;
// }


// img{
// 	max-width: 100%;
// }
.textTitle{
	text-align: center;
	font-size: 34px;
	color: #2E3739;
}
.textSubTitle{
	display: inline-block;
	position: relative;
	height: 28px;
	text-align: center;
	line-height: 28px;
	padding: 0 25px;
	margin-top: 15px;
	background: #D8AF1C;
	font-size: 14px;
	color: #fff;
}
.textSubTitle::before{
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: -14px;
	width: 0;
	height: 0;
	margin: auto;
	border-right: 14px solid #D8AF1C;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
}
.textSubTitle::after{
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	bottom: 0;
	right: -14px;
	width: 0;
	height: 0;
	margin: auto;
	border-left: 14px solid #D8AF1C;
	border-top: 14px solid transparent;
	border-bottom: 14px solid transparent;
	}
.topNav{
	ul{
		display: flex;
		li{
			display: flex;
			flex: 1;
		}
		a{
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			padding: 8px 0 5px;
			text-decoration: none;
			background: #2E3739;
			font-size: 10px;
			color: #fff;
			img{
				width: 25px;
				margin-bottom: 7px;
			}
		}
		li:last-child{
			a{
				img{
					width: 34px;
				}
			}
		}
		li:nth-child(n+2){
			border-left: 1px solid #fff;
		} 
	}
}



.indexNews{
	border-bottom: 1px solid #E5E5E5;
}
.newsHeadingTop{
	font-size: 21px;
	padding: 15px 15px 0;
	text-align: left;
	background: none;
	color: #222;
}
.newsDate,.newsTitle{
	display: block;
	font-size: 11px;
}
.newsDate{
	font-weight: bold;
	color: #2E3739;
}



.reason{
	padding: 60px 15px;
	border-bottom: 1px solid #E5E5E5;
	text-align: center;
}
.reasonList{
}
.reasonList li{
	margin-top: 40px;
}
.reasonList dl{}
.reasonList dt{
	height: 54px;
	line-height: 54px;
	border-radius: 10px 10px 0 0;
	background: #2E3739;
	font-size: 32px;
	color: #fff;
}
.reasonList dd{
	padding: 20px;
	border-radius: 0 0 10px 10px;
	border-bottom: 2px solid #E2E2E2;
	border-right: 2px solid #E2E2E2;
	border-left: 2px solid #E2E2E2;
	p{
		text-align: center;
	}
}
.reasonList .text1{
	margin-top: 20px;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
}
.reasonList .text2{
	margin-top: 12px;
	line-height: 1.8;
	font-size: 13px;
}
.detailButton{
	text-align: center;
}
.detailButton a{
	display: block;
	height: 42px;
	margin-top: 50px;
	line-height: 42px;
	text-decoration: none;
	border-radius: 30px;
	background: #5B8D26;
	font-size: 15px;
	font-weight: bold;
	color: #fff;
}

.banner{
	padding: 30px 15px;
	border-bottom: 1px solid #E5E5E5;
}
.banner .bannerList{
	// display: flex;
	// justify-content: space-between;
}

.banner .bannerLeft,.banner .bannerRight{
	width: 50%;
    box-sizing: border-box;
}
.banner .banner1{
	display: flex;
	// width: 552px;
	border: 1px solid #5B8D26;
}
.banner .banner1 .bannerLeft{
}
.banner .banner1 .bannerRight{
	position: relative;
	padding: 20px 20px 0;
	background: #5B8D26;
	color: #fff;
}
.banner .banner1 .bannerRight .text1{
	line-height: 1.5;
	font-size: 12px;
}
.banner .banner1 .bannerRight .text2{
	margin-top: 10px;
	letter-spacing: 2px;
	font-size: 10px;
}
.banner .banner1 .bannerRight .text3{
	margin-top: 10px;
	text-align: center;
}
.banner .banner1 .bannerRight .text3 a{
	display: block;
	position: relative;
	height: 24px;
	line-height: 24px;
	text-decoration: none;
	background: #fff;
	color: #5B8D26;
	font-size: 10px;
	&:after{
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 8px;
		width: 6px;
		height: 6px;
		margin: auto;
		border-top: 1px solid #5B8D26;
		border-right: 1px solid #5B8D26;
		transform: rotate(45deg);
	}
}
.banner .banner1 .bannerRight .text3 a:hover{
	opacity: 0.7;
}
.banner .banner1 .bannerRight .image{
	position: absolute;
	top: 14%;
	right: 10%;
	width: 44px;
}
.banner .banner2{
	margin-top: 20px;
	display: flex;
	// width: 552px;
	border: 1px solid #2E3739;
}
.banner .banner2 .bannerLeft{
	padding: 25px 25px 0;
	line-height: 1.5;
	background: #F7F7F7;
	font-size: 12px;
	font-weight: bold;
	color: #2E3739;
}
.banner .banner2 .bannerLeft .small{
	display: inline-block;
	line-height: 1.2;
	font-size: 11px;
}
.banner .banner2 .bannerLeft .red{
	font-size: 33px;
	color: #E34545;
}
.banner .banner2 .bannerLeft .pt{
	font-size: 13px;
	color: #E34545;
}
.banner .banner2 .bannerRight{
	padding: 20px 20px 20px;
	background: #2E3739;
	color: #fff;
}
.banner .banner2 .bannerRight .text1{
	line-height: 1.5;
	text-align: center;
	font-size: 12px;
}
.banner .banner2 .bannerRight .text2{
	margin-top: 10px;
	text-align: center;
	letter-spacing: 2px;
	font-size: 10px;
}
.banner .banner2 .bannerRight .text3{
	margin-top: 10px;
	text-align: center;
}
.banner .banner2 .bannerRight .text3 a{
	display: block;
	position: relative;
	height: 24px;
	line-height: 24px;
	text-decoration: none;
	background: #fff;
	color: #2E3739;
	font-size: 10px;
	&:after{
		content: "";
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 8px;
		width: 6px;
		height: 6px;
		margin: auto;
		border-top: 1px solid #5B8D26;
		border-right: 1px solid #5B8D26;
		transform: rotate(45deg);
	}	
}
.banner .banner1 .bannerRight .text3 a:hover{
	opacity: 0.7;
}

.category{
	padding: 60px 15px;
	border-bottom: 1px solid #E5E5E5;  
}
.category .categoryList{
	// display: flex;
	// justify-content: space-between;
	margin-top: 60px;
	li a{
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin-top: 20px;
		p{
			width: 50%;
		}
	}
}
.category .categoryList .txt{
    display: flex;
    justify-content: center;
	align-items: center;
	border: 1px solid #E2E2E2;
	background: #F7F7F7;
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

.ranking{
	padding: 60px 15px;
	.rankList{
		margin-top: 40px;
		text-align: left;
		li{
			display: flex;
			margin-top: 20px;
		}
		.rankDetail em{
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			width: 25px;
			height: 25px;
			border-radius: 25px;
			border: 2px solid #2E3739;
			font-family: Arial,sans-serif;
			font-size: 15px;
			font-weight: bold;
			color: #2E3739;
		}
		#rank1 .rankDetail em{
			background: #D8AF1C;
			border-color: #D8AF1C;
			color: #fff;
		}
		#rank2 .rankDetail em{
			background: #A19F98;
			border-color: #A19F98;
			color: #fff;			
		}
		#rank3 .rankDetail em{
			background: #D17335;
			border-color: #D17335;
			color: #fff;			
		}
		.rankImg,.rankDetail{
			width: 50%;
		}
		.rankImg{
			padding-right: 15px;
		}
		.rankName{
			margin-top: 10px;
			font-size: 15px;
			font-weight: bold;
			color: #333;
		}
		.rankPrice{
			font-family: Arial,sans-serif;
			font-size: 18px;
			font-weight: bold;
			color: #E22828;
		}
		.free{
			display: inline-block;
			margin: 10px 0;
			padding: 5px;
			font-size: 12px;
			font-weight: bold;
			background: #E22828;
			color: #fff;
		}
		.tax{
			font-size: 13px;
		}
	}
}

.search{
	padding: 10px 15px 60px;
	.heading{
		display: flex;
		align-items: center;
		margin: 50px auto 40px;
		padding: 0 40px;
		font-size: 18px;
		font-weight: bold;
	}
	.heading:before,
	.heading:after {
		content: "";
		flex-grow: 1;
		border-top: 1px solid #2E3739;
	}
	.heading:before {
		margin-right: 1rem;
	}
	.heading:after {
		margin-left: 1rem;
	}

	.typeList{
		display: flex;
		justify-content: space-between;
		flex-wrap:wrap;
		margin: -7px;
		li{
			width: 50%;
			padding: 7px;
			box-sizing: border-box;
		}
		a{
			display: inline-block;
			text-decoration: none;
			&:hover{
				opacity: 0.7;
			}
		}
		.image{}
		.txt{
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #F17070;
			font-size: 16px;
			font-weight: bold;
			color: #fff;
			&.type2{background:#B77C17}
			&.type3{background:#378DC7}
			&.type4{background:#BA4697}
			&.type5{background:#5B8D26}
		}
	}
	.links{
		// display: flex;
		// justify-content: space-between;
		.link1 {
			a{
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				// width: 555px;
				// height: 200px;
				padding: 20px;
				text-decoration: none;
				border: 2px solid #E2E2E2;
				border-radius: 5px;
				font-size: 17px;
				color: #000;
				p:first-child{
					flex: 1;
				}
				span{
					font-size: 10px;
					color: #E34545;
				}
				img{
					width: 50px;
					margin: 0 0 0 10px;
				}
				&:hover{
					opacity: 0.7;
				}
			}
		}
		.link2 {
			margin-top: 15px;
			a{
				display: block;
				box-sizing: border-box;
				position: relative;
				// width: 555px;
				// height: 200px;
				padding: 20px;
				text-align: left;
				text-decoration: none;
				border: 2px solid #E2E2E2;
				border-radius: 5px;
				font-size: 15px;
				color: #000;
				.cont1{
					display: flex;
					align-items: center;
					padding-bottom: 10px;
					margin-bottom: 15px;
					border-bottom: 1px solid #2E3739;
				}
				.text1{
					display: inline-block;
					font-size: 19px;
				}
				.text2{
					font-size: 11px;
					color: #E34545;
				}
				.text3{
					font-size: 10px;
				}
				.img1{
					position: relative;
					right: 10px;
					width: 40px;
				}
				.img2{
					width: 40px;
					margin-left: 8px;
				}
				.img3{
					position: absolute;
					top: 0;
					bottom: 0;
					width: 80px;
					right: 15px;
					margin: auto;
				}
				// &:hover{
				// 	opacity: 0.7;
				// }
			}
		}
	}
	.brandList{
		display: flex;
		justify-content: start;
		flex-wrap: wrap;
		margin: 50px -8px 0;
		li{
			box-sizing: border-box;
			width: 50%;
			padding: 7px 25px;
		}
		a{
			display: inline-block;
			text-decoration: none;
			color: #333;
			// &:hover{
			// 	opacity: 0.7;
			// }
		}
		.image{
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 250px;
		}
		.txt{
			text-align: center;
			font-size: 16px;
			font-weight: bold;
		}
	}
	.NETcontentList{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: -7px;
		li:first-child{
			width: 100%;
			a{
				width: 100%;
			}
		}
		li{
			box-sizing: border-box;
			padding: 7px;
			width: 50%;
			a{
				display: inline-block;
				width: 100%;
				height: 60px;
				line-height: 60px;
				text-decoration: none;
				background: #F7F7F7;
				border: 2px solid #E2E2E2;
				border-radius: 5px;
				font-size: 16px;
				color: #000;
				&:hover{
					opacity: 0.7;
				}
			}
		}   
	}
}


.choose{
	padding: 60px 0 0;
	background: #F7F7F7;
	.riceTableWrap{
		overflow-x: scroll;
		 -webkit-overflow-scrolling: touch;
		margin: 50px 15px 5px;
		padding-bottom: 20px;
		&::-webkit-scrollbar{
			height: 6px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 6px;
			background: #eee;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 6px;
			background: #666;
		}
	}
	.scrollText{
		padding: 0 15px;
		font-size: 12px;
		color: #888;
		img{
			width: 19px;
			margin-right: 5px;
		}
	}
	.riceTable{
		width: 100%;
		table-layout: fixed;
		colgroup{
			col:first-child{width:116px}
			col{width:135px;}
		}
		.row1 th{
			padding: 10px;
			text-align: center;
			// width: 116px;
			box-sizing: border-box;
			font-size: 12px;
			// &:first-child{
			// 	width: 200px;
			// }
		}
		tr{
			th:first-child{
				font-size: 12px;
			}
		}
		th,td{
			padding: 4px 4px;
			border: 1px solid #ccc;
			vertical-align: middle;
		}
		th{
			background: #FAF8DF;
		}
		td{
			text-align: center;
			background: #fff
		}
		.comment{
			margin-top: 3px;
			text-align: center;
			font-size: 10px;
			color: #2E3739;
		}
		.detailButton{
			display: block;
			padding: 4px;
			text-decoration: none;
			border: 1px solid #B7B7B7;
			border-radius: 8px;
			background: #F7F7F7;
			font-size: 12px;
			font-weight: bold;
			color: #2E3739;
			&:hover{
				opacity: 0.7;
			}
		}
		.riceIcon{
			display: block;
			width: 76px;
			height: 20px;
			margin: auto;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');
			background-repeat: no-repeat,no-repeat,no-repeat,no-repeat,no-repeat;
			background-position: 0 0, 25% 0, 50% 0, 75% 0, 100% 0;
			background-size: 13px;
		}
		.rice1{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice1-5{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice2{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice2-5{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice3{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice3-5{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice4{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.png');           
		}
		.rice4-5{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice0.5.png');         
		}
		.rice5{
			@extend .riceIcon;
			background-image: url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png'),
				url('//gigaplus.makeshop.jp/fukuikomeya/renew_set/images/top/rice1.png');           
		}
	}
	.riceGraph{
		margin-top: 30px;
	}
}

.about{
	padding: 60px 0;
}





